<template>
  <fieldset v-if="hasHailOutCompany || hasHailInCompany">
    <h4 class="level1">
      {{ $t("section.hail") }}
    </h4>
    <Select
      v-if="hasHailOutCompany"
      v-model="form.hailOutCallCompany"
      :label="$t('fields.hailOutCallCompany')"
      :options="hailOutCallProviderOptions"
      :required="hasHailOutCompany === $const.MANDATORY"
      :refeshError="refreshError"
      @error="checkError"
    />
    <Select
      v-if="hasHailInCompany"
      v-model="form.hailCallCompany"
      :label="$t('fields.hailInCallCompany')"
      :options="hailInCallProviderOptions"
      :required="hasHailInCompany === $const.MANDATORY"
      :refeshError="refreshError"
      @error="checkError"
    />
  </fieldset>
</template>
<script>
import Select from "@/components/Select.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Hail",
  components: {
    Select
  },
  props: {
    refreshError: Number,
    checkError: Function,
    form: Object
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      subform: state => state.editTripSubform.subform
    }),
    hasHailInCompany() {
      return this.getPropertyValue("hasHailInCompany", {
        subforms: this.subform
      });
    },
    hailInCallProviders() {
      return this.getPropertyValue(
        "hailInCallProviders",
        { forms: this.subscription.module.form },
        []
      );
    },
    hailInCallProviderOptions() {
      return this.buildOptions(
        this.hailInCallProviders,
        this.systemsLists.serviceProvider,
        this.hasHailInCompany === this.$const.MANDATORY
      );
    },
    hasHailOutCompany() {
      return this.getPropertyValue("hasHailOutCompany", {
        subforms: this.subform
      });
    },
    hailOutCallProviders() {
      return this.getPropertyValue(
        "hailOutCallProviders",
        {
          forms: this.subscription.module.form,
          subforms: this.subform
        },
        []
      );
    },
    hailOutCallProviderOptions() {
      return this.buildOptions(
        this.hailOutCallProviders,
        this.systemsLists.serviceProvider,
        this.hasHailOutCompany === this.$const.MANDATORY
      );
    }
  },
  watch: {
    hasHailInCompany: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.hailCallCompany;
        }
      }
    },
    hasHailOutCompany: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.hailOutCallCompany;
        }
      }
    }
  }
};
</script>
