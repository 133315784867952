<template>
  <fieldset v-if="hasPorts">
    <h4 class="level1">{{ $t("section.port") }}</h4>
    <GenericInput
      v-if="hasDeparturePort"
      :label="$t('fields.departurePort')"
      v-model="form.departurePort"
      :areasandports="areasAndPorts"
      type="regionsubregion"
      :required="hasDeparturePort === $const.MANDATORY"
      @error="checkError"
      :refeshError="refreshError"
    />
    <GenericInput
      v-if="hasLandingPort"
      :label="$t('fields.arrivalPort')"
      v-model="form.arrivalPort"
      :areasandports="areasAndPorts"
      type="regionsubregion"
      :required="hasLandingPort === $const.MANDATORY"
      @error="checkError"
      :refeshError="refreshError"
    />
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Ports",
  components: {
    GenericInput
  },
  props: {
    form: Object,
    checkError: Function,
    refreshError: Number
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subform: state => state.editTripSubform.subform,
      areasAndPorts: state => state.systemsLists.areasandports
    }),
    hasDeparturePort() {
      return this.getPropertyValue("hasDeparturePort", {
        subforms: this.subform
      });
    },
    hasLandingPort() {
      return this.getPropertyValue("hasLandingPort", {
        subforms: this.subform
      });
    },
    hasPorts() {
      return (
        this.hasDeparturePort !== this.$const.BLOCKED ||
        this.hasLandingPort !== this.$const.BLOCKED
      );
    }
  },
  watch: {
    hasDeparturePort: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.departurePort;
        }
      }
    },
    hasLandingPort: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.arrivalPort;
        }
      }
    }
  }
};
</script>
