var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fieldset',{staticClass:"fieldsetViewTrip"},[_c('h4',{staticClass:"level1 viewStep"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.gearLossStep.loss"))+" "+_vm._s(_vm.index + 1)+" ")]),_c('b-row',[(_vm.gearLoss['occursAt'] && _vm.gearLoss['occursAt']['datetime'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.gearLossStep.lostDate'),"value":_vm.$showDateOnly(_vm.gearLoss['occursAt']['datetime']),"type":"text"}})],1):_vm._e()],1),_c('b-row',[(_vm.gearLoss.occursAt && _vm.gearLoss.occursAt['latitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(_vm.gearLoss.occursAt['latitude'], 'lat') +
              ' = ' +
              _vm.gearLoss.occursAt['latitude'] +
              '°',"type":"text"}})],1):_vm._e(),(_vm.gearLoss.occursAt && _vm.gearLoss.occursAt['longitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(_vm.gearLoss.occursAt['longitude'], 'lon') +
              ' = ' +
              _vm.gearLoss.occursAt['longitude'] +
              '°',"type":"text"}})],1):_vm._e()],1),_c('b-row',[(_vm.gearLoss['gearType'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.gearLossStep.gearDesc'),"value":_vm.gearLoss['gearType'],"options":_vm.gearTypes,"type":"select"}})],1):_vm._e(),(_vm.gearLoss['nbGear'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.gearLossStep.nbGearOld'),"value":_vm.gearLoss['nbGear'],"type":"text"}})],1):_vm._e(),(_vm.gearLoss['tags'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[(_vm.gearLoss['tags'].length > 0)?_c('LabelView',{attrs:{"label":_vm.$t('editTrip.gearLossStep.tags'),"value":_vm.gearLoss['tags'],"type":"text"}}):_vm._e()],1):_vm._e()],1),(_vm.gearLoss['remark'])?_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.remark'),"value":_vm.gearLoss['remark'],"type":"text"}})],1)],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }