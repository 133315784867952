<template>
  <fieldset>
    <h4 class="level1">{{ $t("baitFished.title") }}</h4>
    <BaitFished
      v-for="(bait, index) in baits"
      :key="'baitFishedEntry' + index"
      :bait="bait"
      :showErrorData="showErrorData + refreshIndex[index]"
      :addError="addError"
      :index="index"
      :species="species"
      :baitLen="baits.length"
      @update="updateBait"
    />
    <div class="suppEntryButtonWrapper" style="display: block;">
      <div>
        <input
          v-if="baits.length > 0 && !$isLastItemClosed(this.baits)"
          @click="removeBait"
          class="removeSuppEntryButton"
          type="button"
          :value="$t('baitFished.remove') + String(baits.length)"
        />
        <input
          @click="addBait"
          class="addSuppEntryButton"
          type="button"
          :value="$t('baitFished.add')"
        />
      </div>
    </div>
  </fieldset>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import BaitFished from "@/components/subformEditTrip/BaitFished.vue";

const defaultBait = function(form) {
  return {
    remark: null,
    species: null,
    productForm: form,
    weight: null
  };
};

export default {
  name: "BaitFishedManager",
  components: {
    BaitFished
  },
  props: {
    showErrorData: Number,
    addError: Function,
    baits: Array
  },
  data: () => ({
    refreshIndex: [],
    baitsFished: [],
    species: []
  }),
  computed: {
    ...mapGetters(["getPropertyValue", "errorMessageDateOverlap"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      openTrip: state => state.currentOpenTrip,
      subscription: state => state.currentSubscription,
      subform: state => state.editTripSubform.subform,
      userProfile: state => state.userProfile,
      stateErrors: state => state.editTripSubform.errors
    }),
    baitFishedSpecies() {
      return this.getPropertyValue("baitFishedSpecies", {
        subforms: this.subform
      });
    }
  },
  methods: {
    updateBait(bait) {
      // console.error("updatebait", bait);
    },
    async addBait() {
      await this.$nextTick();

      const errorsFieldsNames = keysMatchingValue(
        this.stateErrors,
        true,
        `baitStep-${this.baitsFished.length - 1}`
      );
      if (errorsFieldsNames.length > 0) {
        scrollToTopField(errorsFieldsNames);
        return;
      }

      this.baitsFished.push(defaultBait(this.$const.FORM_ROUND));
      this.refreshIndex.push(0);
    },
    removeBait() {
      this.baitsFished.pop();
      this.refreshIndex.pop();
    }
  },
  beforeMount() {
    this.species = [{ value: null, text: "---------", disabled: true }];
    this.baitFishedSpecies.forEach(specie => {
      const result = this.systemsLists.species.find(x => x.value === specie);
      if (result) {
        this.species.push(result);
      }
    });
    this.baitsFished = this.baits;
    if (this.baitsFished.length > 0)
      this.refreshIndex = Array(this.baitsFished.length).fill(0);
  },
  mounted() {}
};
</script>
