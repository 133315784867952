<template>
  <div v-if="needDisplayAditionalInfo">
    <h4 class="level1">
      {{ $t("editTrip.lastStep.additionalInfo") }}
    </h4>
    <div>
      <div>
        <fieldset class="fieldsetViewTrip">
          <b-row>
            <b-col v-if="currentTrip['weightOnBoard']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.lastStep.weightOnBoardAditionalInfo')"
                :value="currentTrip['weightOnBoard']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="currentTrip['nbFrozenBlocks']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.lastStep.nbFrozenBlocks')"
                :value="currentTrip['nbFrozenBlocks']"
                type="text"
              />
            </b-col>
            <b-col
              v-if="currentTrip['nbFreshContainers']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('editTrip.lastStep.nbFreshContainers')"
                :value="currentTrip['nbFreshContainers']"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "AdditionalInfoViewer",
  components: {
    LabelView,
    UnitConverter
  },
  computed: {
    ...mapState({
      currentTrip: state => state.selectedTrip
    }),
    needDisplayAditionalInfo() {
      return (
        this.currentTrip["weightOnBoard"] ||
        this.currentTrip["nbFrozenBlocks"] ||
        this.currentTrip["nbFreshContainers"]
      );
    }
  }
};
</script>
