var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"field-wrapper",attrs:{"name":_vm.id}},[(_vm.label)?_c('span',{staticClass:"label"},[_c('span',[_c('label',[_vm._v(" "+_vm._s(_vm.label)+" ")])]),(_vm.hasHelp)?_c('a',{staticClass:"dot",attrs:{"id":'tooltipButton' + _vm.id}},[_vm._v(" ? ")]):_vm._e(),(_vm.hasHelp)?_c('b-tooltip',{attrs:{"target":'tooltipButton' + _vm.id,"title":_vm.help,"placement":"topleft","triggers":"click","variant":"primary"}}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"datetimeWidget"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.date),expression:"date"}],staticClass:"datetime",class:{
        error: _vm.errorClass.error,
        date: !_vm.hideTime,
        dateHideTime: _vm.hideTime,
        removeAutodatetime: !_vm.autodatetime
      },attrs:{"disabled":_vm.disabled,"type":"date"},domProps:{"value":(_vm.date)},on:{"blur":_vm.formatDatetime,"input":function($event){if($event.target.composing)return;_vm.date=$event.target.value}}}),(!_vm.hideTime)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.time),expression:"time"}],staticClass:"datetime time",class:{
        error: _vm.errorClass.error,
        removeAutodatetime: !_vm.autodatetime
      },attrs:{"disabled":_vm.disabled,"type":"time"},domProps:{"value":(_vm.time)},on:{"blur":_vm.formatDatetime,"input":function($event){if($event.target.composing)return;_vm.time=$event.target.value}}}):_vm._e(),(_vm.autodatetime)?_c('input',{staticClass:"autodatetime",attrs:{"disabled":_vm.disabled,"type":"button"},on:{"click":function($event){return _vm.autodatetimeClick()}}}):_vm._e()]),(_vm.errorMessage)?_c('label',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }