import Base from "./Base.js";

export default class PersonalUse extends Base {
  species = null;
  productForm = null;
  remark = null;
  weight = null;
  kept = null;
  usage = null;
  size = null;
  constructor() {
    super();
  }
}
