<template>
  <div v-if="onlineAccessRequired">
    <div class="topContentError topContentMessage" v-if="showMessage">
      {{ $t(getOnlineError) }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters("api", ["isOnline", "getOnlineError"]),
    showMessage() {
      return !this.isOnline && this.getOnlineError;
    },
    onlineAccessRequired() {
      return this.$store.getters.IS_ONLINE_ACCESS_REQUIRED;
    }
  },
  methods: {
    updateOnlineStatus(e) {
      // const { type } = e;
      // this.isOnline = type === "online";
      // this.$store.commit("SET_ONLINE", this.isOnline);
    }
  },
  mounted() {
    // window.addEventListener("online", this.updateOnlineStatus);
    // window.addEventListener("offline", this.updateOnlineStatus);
  },
  beforeDestroy() {
    // window.removeEventListener("online", this.updateOnlineStatus);
    // window.removeEventListener("offline", this.updateOnlineStatus);
  }
};
</script>
