<template>
  <fieldset>
    <h4 class="level2">
      <span>
        <span>
          {{ $t("editTrip.summary.title") }}
        </span>
      </span>
    </h4>
    <br />
    <b-row>
      <b-col v-if="openTrip.logbookUID" md="6" cols="12">
        <LabelView
          :floatLeft="true"
          :label="$t('editTrip.subscriptionStep.logbookUID')"
          :value="openTrip.logbookUID"
        />
      </b-col>
      <b-col
        v-for="(hailout, index) in openTrip.hailOuts"
        :key="'hailout' + index"
        md="6"
        cols="12"
      >
        <LabelView
          v-if="hailout.number"
          :floatLeft="true"
          :label="$t('editTrip.hailOutNumber') + ' #' + (index + 1)"
          :value="hailout.number"
          type="text"
        />
      </b-col>
      <b-col v-if="openTrip.vessel?.registeredNumber" md="6" cols="12">
        <LabelView
          :floatLeft="true"
          :label="$t('editTrip.lastStep.vesselNumber')"
          :value="openTrip.vessel.registeredNumber"
          type="text"
        />
      </b-col>
      <b-col md="6" cols="12">
        <LabelView
          :floatLeft="true"
          :label="$t('catch.keptWeight')"
          :value="targetWeight"
          :extra="' kg'"
          type="text"
        />
      </b-col>
    </b-row>
  </fieldset>
</template>
<script>
import LabelView from "@/components/LabelView.vue";

import { mapGetters, mapState } from "vuex";

export default {
  name: "Summary",
  components: {
    LabelView
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      openTrip: state => state.currentOpenTrip
    }),
    targetWeight() {
      return this.openTrip.efforts.reduce((effortWeight, effort) => {
        return (
          effortWeight +
          effort.tows.reduce((towTotal, tow) => {
            return (
              towTotal +
              tow.catches.reduce((captureTotal, capture) => {
                return capture.species === effort.target
                  ? captureTotal + capture.keptWeight
                  : captureTotal;
              }, 0)
            );
          }, 0)
        );
      }, 0);
    }
  }
};
</script>
