<template>
  <fieldset
    v-if="mminteration['hasMmi'] === $const.YES"
    :disabled="mminteration['closeDatetime'] !== undefined"
  >
    <h4>Identification</h4>
    <GenericInput
      :label="$t('fields.fullname')"
      v-model="mminteration['context']['name']"
      type="text"
      :required="true"
      :maxlength="80"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'fullname-' + index"
    />
    <GenericInput
      :label="$t('fields.address')"
      v-model="mminteration['context']['address']"
      type="text"
      :required="true"
      :maxlength="200"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'address-' + index"
    />
    <Select
      :label="$t('province.label')"
      v-model="mminteration['context']['province']"
      :options="$t('province.items')"
      :required="true"
      :key="'province-' + index"
    />
    <GenericInput
      :label="$t('fields.email')"
      v-model="mminteration['context']['email']"
      type="email"
      :required="true"
      :maxlength="50"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'email-' + index"
    />
    <br />
    <GenericInput
      :label="$t('fields.phone')"
      v-model="mminteration['context']['phone']"
      type="phone"
      :required="true"
      :phoneUS="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'phone-' + index"
    />
  </fieldset>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";

export default {
  components: {
    GenericInput,
    Select
  },
  props: {
    addErrorToParent: Function,
    parentShowError: Number,
    mminteration: Object,
    index: Number
  },
  data() {
    return {
      name: "Identification"
    };
  }
};
</script>
