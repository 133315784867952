import JobelClient from "@/service/api";

// big objects should be free of nulls when sent to Jobel server
var copyObjectWithoutNull = function(object, nullEmptyObject = false) {
  if (typeof object != "object") return object;

  var result = {};
  const keys = Object.keys(object);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const element = object[key];
    if (element != null) {
      // otherwise, array are considered type object
      if (Array.isArray(element)) {
        const subArray = [];
        for (let j = 0; j < element.length; j++) {
          const subElement = copyObjectWithoutNull(element[j], nullEmptyObject);
          if (subElement) {
            subArray.push(subElement);
          }
        }
        result[key] = subArray;
      } else if (typeof element === "object") {
        const subObject = copyObjectWithoutNull(element, nullEmptyObject);
        if (Object.keys(subObject).length > 0) {
          result[key] = subObject;
        } else if (nullEmptyObject) {
          result[key] = null;
        }
      } else {
        result[key] = element;
      }
    }
  }
  return result;
};

var generateExperimentalEffort = function(experimentalData) {
  let experimentalEffort = {};
  let effortIndex = 0;
  for (let gearId in experimentalData) {
    for (let rowIndex in experimentalData[gearId]) {
      for (let catchTypeId in experimentalData[gearId][rowIndex]) {
        if (experimentalData[gearId][rowIndex][catchTypeId] != null) {
          experimentalEffort[effortIndex] = {
            gearId: gearId,
            catchTypeId: catchTypeId,
            value: experimentalData[gearId][rowIndex][catchTypeId]
          };
          ++effortIndex;
        }
      }
    }
  }
  return experimentalEffort;
};

export default {
  namespaced: true,
  state: {
    client: JobelClient.default(),
    language: null,
    token: null,
    error: null,
    monitor: false,
    testing: false
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
    setToken(state, token) {
      state.token = token;
    },
    setMonitor(state, monitor) {
      state.monitor = monitor;
    },
    setTesting(state, testing) {
      state.testing = testing;
    }
  },
  getters: {
    isOnline: state => {
      return state.error == null;
    },
    getOnlineError: state => {
      return state.error;
    },
    isAuthenticated: state => {
      return state.token;
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    post({ dispatch, commit, state, rootState }, { action, data, timeout }) {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      if (state.token) {
        formData.append("offlineToken", state.token);
      }
      if (rootState.language) {
        formData.append("language", rootState.language);
      }

      // @TODO: check if null options works on client.post instead of this if/else
      let postPromise;
      if (timeout) {
        postPromise = state.client.post(`jc/${action}/`, formData, {
          timeout: timeout
        });
      } else {
        postPromise = state.client.post(`jc/${action}/`, formData);
      }

      return postPromise
        .then(response => {
          commit("clearError");
          return response;
        })
        .catch(error => {
          if (error.errorType == "session") {
            dispatch("lostSession", null, { root: true }).finally(() =>
              dispatch("setToken", null)
            );
            // .then(() =>
            //   Promise.reject(error)
            // );
          }
          if (["timeout", "unavailable", "network"].includes(error.errorType)) {
            commit("setError", error.i18n);
          }
          throw error;
        });
    },
    needInternet({ dispatch, commit, state }, isNeeded = true) {
      if (isNeeded != state.monitor) commit("setMonitor", isNeeded);

      if (state.monitor) {
        if (state.testing) {
          return Promise.resolve(true);
        }
        commit("setTesting", true);
        return dispatch("testConnection")
          .catch(error => {
            setTimeout(() => {
              if (state.monitor) {
                dispatch("needInternet");
              }
            }, 5000);
          })
          .finally(() => commit("setTesting", false));
      }
      return Promise.resolve(true);
    },
    login({ dispatch, commit }, form) {
      return dispatch("post", { action: "login", data: form }).then(data => {
        commit("setToken", data.offlineToken);
        return Promise.resolve(data);
      });
    },
    logout({ dispatch }) {
      return dispatch("post", { action: "logout" });
    },
    signup({ dispatch }, { fin, captcha }) {
      return dispatch("post", {
        action: "signup",
        data: {
          fin: fin,
          captcha_text: captcha.text,
          captcha_id: captcha.id
        }
      });
    },
    signupComplete({ dispatch }, { fin, captcha, password, form }) {
      // most fields are correctly named but some divergences
      let data = {
        fin: fin,
        captcha_text: captcha.text,
        captcha_id: captcha.id,
        password: password,
        fisherIdentificationNumber: fin,
        first_name: form.firstname,
        last_name: form.lastname,
        phoneNumber: "+1" + form.phoneNumber
      };

      const samefields = [
        "address",
        "province",
        "timezone",
        "email",
        "dfoRegion",
        "securityQuestion1",
        "answer1",
        "securityQuestion2",
        "answer2",
        "securityQuestion3",
        "answer3"
      ];
      for (const key of samefields) {
        data[key] = form[key];
      }

      return dispatch("post", {
        action: "signupComplete",
        data: data
      });
    },
    getSystemsLists({ dispatch }, hash = null) {
      let data = {};
      if (hash) {
        data["updated"] = hash;
      }
      return dispatch("post", { action: "getSystemsLists", data: data }).then(
        data => {
          return Promise.resolve(data.systemsLists);
        }
      );
    },
    getUserProfile({ dispatch }) {
      return dispatch("post", { action: "getUserProfile" }).then(data => {
        return Promise.resolve(data.profile);
      });
    },
    editUserProfile({ dispatch }, form) {
      // most fields are correctly named but some divergences
      let data = {
        fisherIdentificationNumber: form.username,
        first_name: form.firstname,
        last_name: form.lastname,
        phoneNumber: "+1" + form.phoneNumber
      };
      const samefields = [
        "address",
        "province",
        "timezone",
        "email",
        "dfoRegion",
        "securityQuestion1",
        "answer1",
        "securityQuestion2",
        "answer2",
        "securityQuestion3",
        "answer3"
      ];
      for (const key of samefields) {
        data[key] = form[key];
      }

      return dispatch("post", {
        action: "editUserProfile",
        data: data
      }).then(data => {
        return Promise.resolve(data.profile);
      });
    },
    editElogKey({ dispatch }, key) {
      return dispatch("post", {
        action: "editElogKey",
        data: { elogkey: key }
      });
    },
    editPassword({ dispatch }, { oldPassword, newPassword }) {
      return dispatch("post", {
        action: "editPassword",
        data: {
          old_password: oldPassword,
          new_password1: newPassword,
          new_password2: newPassword
        }
      });
    },
    resetPassword({ dispatch }, { fin, captcha }) {
      return dispatch("post", {
        action: "resetPassword",
        data: {
          fin: fin,
          captcha_text: captcha.text,
          captcha_id: captcha.id
        }
      });
    },
    newPassword({ dispatch }, { user, token, password }) {
      return dispatch("post", {
        action: "newPassword",
        data: {
          user: user,
          token: token,
          password1: password,
          password2: password
        }
      });
    },
    verifyToken({ dispatch }, { user, token }) {
      return dispatch("post", {
        action: "verifyToken",
        data: {
          user: user,
          token: token
        }
      });
    },
    getAvailableModules({ dispatch }) {
      return dispatch("post", { action: "getAvailableModules" }).then(data => {
        return Promise.resolve(data.modules);
      });
    },
    getOrder({ dispatch }, uuid) {
      return dispatch("post", {
        action: "getOrder",
        data: { module: uuid }
      }).then(data => {
        return Promise.resolve(data.order);
      });
    },
    processOrder({ dispatch }, form) {
      return dispatch("post", { action: "processOrder", data: form }).then(
        data => {
          return Promise.resolve(data.subscription);
        }
      );
    },
    cancelOrder({ dispatch }, uuid) {
      return dispatch("post", {
        action: "cancelOrder",
        data: { order: uuid }
      });
    },
    getTransactions({ dispatch }) {
      return dispatch("post", { action: "getTransactions" }).then(data => {
        return Promise.resolve(data.transactions);
      });
    },
    getSubscriptions({ dispatch }) {
      return dispatch("post", {
        action: "getSubscriptions",
        timeout: 20000
      }).then(data => {
        return Promise.resolve(data.subscriptions);
      });
    },
    getInactivities({ dispatch }, data) {
      const params = { id: data.id };
      if (data?.lastmodifiedtimestamp) {
        params.lastmodifiedtimestamp = data.lastmodifiedtimestamp;
      }

      return dispatch("post", {
        action: "getInactivities",
        data: params,
        timeout: 20000
      }).then(function(data) {
        var formList = data.inactivities;
        for (let form of formList) {
          form.inactivity = {
            details: form.details,
            reportUID: form.reportUID,
            remark: form.remark
          };
          // @TODO: verify if we still need moving details, remark, etc
          // delete form.details;
          // delete form.remark;
        }

        return Promise.resolve(data);
      });
    },
    getTrips({ dispatch }, data) {
      const params = { subscriptionUUID: data.subscriptionUUID };
      if (data?.lastmodifiedtimestamp) {
        params.lastmodifiedtimestamp = data.lastmodifiedtimestamp;
      }

      return dispatch("post", {
        action: "getTrips",
        data: params,
        timeout: 20000
      });
    },
    getTrip({ dispatch }, form) {
      return dispatch("post", {
        action: "getTrip",
        data: form,
        timeout: 20000
      }).then(data => {
        return Promise.resolve(data.trip);
      });
    },
    getArchives({ dispatch }, form) {
      return dispatch("post", {
        action: "getArchives",
        data: form,
        timeout: 20000
      }).then(data => {
        return Promise.resolve(data.trips);
      });
    },
    getXML({ dispatch }, fileId) {
      return dispatch("post", {
        action: "getXML",
        data: { fid: fileId }
      });
    },
    editModuleSettings({ dispatch, rootGetters }, form) {
      const subscription = rootGetters.SUBSCRIPTION;
      var data = copyObjectWithoutNull(form, true);
      return dispatch("post", {
        action: "editModuleSettings",
        data: {
          subscriptionUUID: subscription.uuid,
          settings: JSON.stringify(data)
        }
      });
    },
    pushInactivity({ dispatch }, { uuid, form }) {
      var data = copyObjectWithoutNull(form);
      let inactivity = { ...data, ...data.inactivity }; // flatten before sending
      delete inactivity.inactivity;

      return dispatch("post", {
        action: "pushInactivity",
        data: {
          id: uuid,
          inactivity: JSON.stringify(inactivity)
        }
      });
    },
    pushTrip({ dispatch }, { uuid, form }) {
      const NO = 11619;

      // TODO: Ces bouts de code ne devraient pas se trouver ici.
      // Ça ressemble trop à des patches vite fait :-(

      //remove all ghost data from interaction[0] if hasMmi is NO
      if (
        form.interactions &&
        form.interactions.length === 1 &&
        form.interactions[0].hasMmi === NO
      ) {
        form.interactions[0] = {
          hasMmi: NO,
          creation: form.interactions[0].creation,
          remark: form.interactions[0].remark
        };
      }

      if (
        form.inactivity &&
        form.inactivity !== null &&
        !form.inactivity.reportUID
      ) {
        delete form.inactivity;
      }

      if (form.experimentalData) {
        form.experimentalEfforts = generateExperimentalEffort(
          form.experimentalData
        );
      }

      form.completedBy = process.env.VUE_APP_VERSION;
      var data = copyObjectWithoutNull(form);

      return dispatch("post", {
        action: "pushTrip",
        data: {
          subscriptionUUID: uuid,
          trip: JSON.stringify(data)
        }
      });
    },
    testConnection({ dispatch }) {
      return dispatch("post", { action: "testConnection", data: { d: true } });
    }
  }
};
