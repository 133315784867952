<template>
  <fieldset class="gears" v-if="nbFieldsVisible > 0">
    <h4 class="level1">{{ $t("section.gears") }}</h4>
    <Select
      v-if="hasEffortGearSubtype"
      v-model="form.subType"
      :label="$t('editTrip.effortStep.subType')"
      :options="gearSubtypeOptions"
      :required="hasEffortGearSubtype === $const.MANDATORY"
      :refeshError="refreshError"
      @error="checkError"
    />
    <Select
      v-if="hasTrapSize"
      v-model="form.trapSize"
      :label="$t('fields.trapSize')"
      :options="trapSizeOptions"
      :required="hasTrapSize === $const.MANDATORY"
      :refeshError="refreshError"
      @error="checkError"
    />
  </fieldset>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Select from "@/components/Select.vue";
import { mapping } from "@/utils/FormStateMapping";

export default {
  name: "Gears",
  components: {
    Select
  },
  props: {
    checkError: Function,
    refreshError: Number,
    form: Object
  },
  computed: {
    ...mapState({
      dfoFormId: state => state.currentSubscription.module.form,
      systemsLists: state => state.systemsLists
    }),
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    hasEffortGearSubtype() {
      return this.getPropertyValue("hasEffortGearSubtype", {
        subforms: this.form.subform,
        gears: this.form.gears
      });
    },
    gearSubtypes() {
      return this.getPropertyValue(
        "gearSubtypes",
        {
          form: this.dfoFormId,
          gears: this.form.gears
        },
        []
      );
    },
    gearSubtypeOptions() {
      return this.buildOptions(
        this.gearSubtypes,
        this.systemsLists.gearSubTypes,
        this.hasEffortGearSubtype === this.$const.MANDATORY
      );
    },
    hasTrapSize() {
      return this.getPropertyValue("hasTrapSizeId", {
        subforms: this.form.subform,
        gears: this.form.gears
      });
    },
    trapSizes() {
      return this.getPropertyValue("trapSizes", { form: this.dfoFormId }, []);
    },
    trapSizeOptions() {
      return this.buildOptions(
        this.trapSizes,
        this.systemsLists.trapSizes,
        this.hasTrapSize === this.$const.MANDATORY
      );
    },
    nbFieldsVisible() {
      var total = 0;
      if (this.hasEffortGearSubtype !== this.$const.BLOCKED) ++total;
      if (this.hasTrapSize !== this.$const.BLOCKED) ++total;
      return total;
    }
  },
  watch: {
    hasTrapSize: {
      immediate: true,
      handler: function(newValue) {
        if (newValue === this.$const.BLOCKED) {
          delete this.form.trapSize;
        }
      }
    },
    hasEffortGearSubtype: {
      immediate: true,
      handler: function(newValue) {
        if (newValue === this.$const.BLOCKED) {
          delete this.form.subType;
        }
      }
    }
  }
};
</script>
