<template>
  <div v-if="cargoEvents.length > 0" class="list">
    <h4 class="level2">
      {{ $t("editTrip.summary.weighings") }}
    </h4>
    <div
      v-for="(cargoEvent, cargoEventIndex) in cargoEvents"
      :key="'cargoEvent-' + cargoEventIndex"
    >
      <fieldset
        class="fieldsetDetailsBorder"
        v-for="(cargo, cargoIndex) in cargoEvent.cargos"
        :key="'cargo-' + cargoIndex"
      >
        <h4 v-if="cargo.species" class="level3">
          {{ title(cargo.species) }}
        </h4>
        <Select
          v-else
          v-model="cargo.species"
          :label="$t('editTrip.lastStep.specie')"
          :options="cargoSpecies"
          :required="true"
          :refeshError="showErrorData"
          @error="addError"
        />
        <UnitConverter
          v-model="cargo.weight"
          :label="$t('landing.weighing')"
          :min="0"
          :max="999999.999"
          :refresh="showErrorData"
          unit="weight"
          @error="addError"
        />
        <fieldset v-if="hasLandingDetail">
          <UnitConverter
            v-model="cargo.weightFromSet"
            :label="$t('editTrip.lastStep.weightFromSet')"
            :required="true"
            :min="0"
            :max="999999.999"
            :refresh="showErrorData"
            unit="weight"
            @error="addError"
          />
          <UnitConverter
            v-model="cargo.weightFromPond"
            :label="$t('editTrip.lastStep.weightFromPond')"
            :required="true"
            :min="0"
            :max="999999.999"
            :refresh="showErrorData"
            unit="weight"
            @error="addError"
          />
          <GenericInput
            v-model="cargo.pondFromNumber"
            :label="$t('editTrip.lastStep.pondNumber')"
            :help="$t('editTrip.lastStep.pondNumberHelp')"
            :maxlength="30"
            :refeshError="showErrorData"
            :required="cargo.weightFromPond > 0"
            type="text"
            @error="addError"
          />
          <Select
            v-model="cargo.productTreatment"
            :label="$t('editTrip.lastStep.productTreatment')"
            :options="productTreatmentOptions"
            :refeshError="showErrorData"
            @error="addError"
          />
          <GenericInput
            v-model="cargo.remark"
            :label="$t('editTrip.remarkTitle')"
            :maxlength="2000"
            :refeshError="showErrorData"
            type="text"
            @error="addError"
          />
        </fieldset>
      </fieldset>
      <p>
        <input
          v-if="cargoEvent.cargos.length > numberOfMandatoryCargos"
          :value="$t('editTrip.lastStep.removeLandingDetail')"
          class="removeSuppEntryButton"
          type="button"
          @click="removeDetail(cargoEventIndex)"
        />
        <input
          v-if="cargoSpecies.length > 0"
          :value="$t('editTrip.lastStep.addLandingDetail')"
          class="addSuppEntryButton"
          type="button"
          @click="addDetail(cargoEventIndex)"
        />
      </p>
    </div>
  </div>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Select from "@/components/Select.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    GenericInput,
    UnitConverter,
    Select
  },
  props: {
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    },
    cargoEvents: {
      type: Array,
      required: true
    },
    subform: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      error: {},
      cargoSpecies: [], // TODO: Verify if this data prop could not be implemented as a computed prop.
      showErrorData: 0,
      page: "arrivalDetail"
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      mvSpecies: state => state.systemsLists.species,
      productTreatment: state => state.systemsLists.productTreatment,
      currentTrip: state => state.selectedTrip
    }),
    numberOfMandatoryCargos() {
      let amount = 1;
      if (this.hasLandingBluefinTunaWeight && !this.hasBluefinTunaInCatches)
        ++amount;
      return amount;
    },
    hasLandingDetail() {
      return this.getPropertyValue("hasLandingDetail", {
        subforms: this.subform
      });
    },
    hasLandingBluefinTunaWeight() {
      return this.getPropertyValue("hasLandingBluefinTunaWeight", {
        subforms: this.subform
      });
    },
    hasBluefinTunaInCatches() {
      for (let i = 0; i < this.currentTrip.efforts.length; ++i) {
        const effort = this.currentTrip.efforts[i];
        for (let j = 0; j < effort.tows.length; ++j) {
          const tow = effort.tows[j];
          if (
            tow.catches.findIndex(object => {
              return object.species === this.$const.BLUEFINTUNA_SPC;
            }) >= 0
          ) {
            return true;
          }
        }
      }
      return false;
    },
    productTreatmentOptions() {
      return [
        {
          value: null,
          text: "---------"
        },
        ...this.productTreatment
      ];
    }
  },
  watch: {
    showErrorDataParent: function() {
      ++this.showErrorData;
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    title(specie) {
      return this.mvSpecies.find(x => x.value === specie)?.text;
    },
    hasOneMainField(catchItem) {
      // Rule 762: In each occurrence of node CATCH, at least
      //           one of these elements must contain a value.
      return (
        catchItem.keptWeight != null ||
        catchItem.keptNbSpecimen != null ||
        catchItem.discNbSpecimen != null ||
        catchItem.specimenCaught != null ||
        catchItem.discWeight != null
      );
    },
    // TODO: Rewrite these embedded loops using "for of" or a better method.
    fillCargoSpecies(cargos) {
      this.cargoSpecies = [];
      for (let effort in this.currentTrip.efforts) {
        for (let tow in this.currentTrip.efforts[effort].tows) {
          for (let katch in this.currentTrip.efforts[effort].tows[tow]
            .catches) {
            if (
              !this.cargoSpecies.find(
                x =>
                  x.value ==
                  this.currentTrip.efforts[effort].tows[tow].catches[katch]
                    .species
              ) &&
              !cargos.find(
                x =>
                  x.species ==
                  this.currentTrip.efforts[effort].tows[tow].catches[katch]
                    .species
              ) &&
              this.hasOneMainField(
                this.currentTrip.efforts[effort].tows[tow].catches[katch]
              )
            ) {
              this.cargoSpecies.push(
                this.mvSpecies.find(
                  x =>
                    x.value ==
                    this.currentTrip.efforts[effort].tows[tow].catches[katch]
                      .species
                )
              );
            }
          }
        }
      }
    },
    addDetail(index) {
      ++this.showErrorData;
      const vm = this;
      vm.$nextTick(() => {
        if (
          !vm.$hasInputErrorAndScroll() &&
          vm.cargoEvents[index].cargos[vm.cargoEvents[index].cargos.length - 1]
            .species != null
        ) {
          vm.cargoEvents[index].cargos.push({
            productForm: this.$const.FORM_ROUND,
            species: null,
            weightFromPond: null,
            weightFromSet: null
          });
          vm.fillCargoSpecies(vm.cargoEvents[index].cargos);
          if (vm.cargoSpecies.length === 1) {
            vm.cargoEvents[index].cargos[
              vm.cargoEvents[index].cargos.length - 1
            ].species = vm.cargoSpecies[0].value;
            vm.cargoSpecies = [];
          }
          ++vm.showErrorData;
        }
      });
    },
    removeDetail(index) {
      const vm = this;
      ++vm.showErrorData;
      vm.cargoEvents[index].cargos.pop();
      vm.fillCargoSpecies(vm.cargoEvents[index].cargos);
    },
    addError(error) {
      this.addErrorsToState({ page: this.page, errors: error });
      this.addErrorToParent(error);
      this.error = { ...this.error, ...error };
    }
  },
  mounted() {
    if (this.cargoEvents.length > 0) {
      this.fillCargoSpecies(this.cargoEvents[0].cargos); // TODO: Verify if we really need to restrict the search to the first cargo event ?
    }
  }
};
</script>
