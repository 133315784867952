<template>
  <div>
    <div
      v-for="(bait, index) in baits"
      :key="'baitfished-' + index"
      class="towEntriesContainer"
    >
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="level1 viewStep">
            {{ $t("baitFished.label") }}&nbsp;{{ index + 1 }}
          </h4>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.specie')"
                :value="bait.species"
                :options="species"
                type="select"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.productForm')"
                :value="bait.productForm"
                :options="productForms"
                type="select"
              />
            </b-col>
            <b-col lg="4" cols="6">
              <UnitConverter
                :label="$t('baitFished.weight')"
                :value="bait.weight"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col lg="4" cols="6" v-if="bait.remark">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="bait['remark']"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "BaitFishedViewer",
  components: {
    LabelView,
    UnitConverter
  },
  props: {
    baits: Array
  },
  computed: {
    ...mapState({
      species: state => state.systemsLists.species,
      productForms: state => state.systemsLists.productForm
    })
  }
};
</script>
