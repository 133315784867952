<template>
  <div name="inactivityViewer">
    <h4 class="level1 viewStep">
      {{ $t("editTrip.inactivityStep.periods") }}
    </h4>
    <fieldset v-if="!hideGeneralInfo">
      <h4 class="level3">
        {{ $t("editTrip.inactivityStep.info") }}
      </h4>
      <b-row>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.inactivityStep.reportUID')"
            :value="inactivity['reportUID']"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col v-if="inactivity['remark']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.remarkTitle')"
            :value="inactivity['remark']"
            type="text"
          />
        </b-col>
        <b-col v-if="inactivity['closeDatetime']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('dataGroupClosureDate')"
            :value="$showDate(inactivity['closeDatetime'])"
            type="text"
          />
        </b-col>
      </b-row>
    </fieldset>
    <div
      v-for="(detail, index) in inactivity.details"
      :key="'detail-' + index"
      class="towEntriesContainer"
    >
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="level2 viewStep sticky">
            {{ $t("editTrip.inactivityStep.period") }}&nbsp;{{ index + 1 }}
          </h4>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :value="detail['licenceNumber']"
                :label="$t('editTrip.effortStep.licenceNumber')"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :value="$showDate(detail['start'])"
                :label="$t('editTrip.inactivityStep.start')"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :value="$showDate(detail['end'])"
                :label="$t('editTrip.inactivityStep.end')"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :value="detail['reason']"
                :label="$t('editTrip.inactivityStep.reason')"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="detail['remark']" lg="4" sm="6" cols="12">
              <LabelView
                :value="detail['remark']"
                :label="$t('editTrip.remarkTitle')"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
export default {
  name: "InactivityViewer",
  components: {
    LabelView
  },
  props: {
    inactivity: {
      type: Object,
      required: true
    },
    hideGeneralInfo: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({}),
  computed: {
    ...mapState({
      userProfile: state => state.userProfile //utilisé dans $showdate, car $showdate utilise le context de celui qui l'appelle.
    })
  }
};
</script>
