<template>
  <div class="list">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.speciesAtRiskStep.title2") }}</span>
        </span>
      </span>
    </h3>
    <fieldset
      v-for="(mminteration, index) in form.interactions"
      :key="index"
      class="removeBottomPadding"
    >
      <h4 class="level1" :id="'interactions' + index">
        {{ $t("editTrip.speciesAtRiskStep.mmInteraction") }}
        {{ index + 1 }}
        <span
          v-b-modal="'mmiSuppSpeciesAtRiskEntry-' + index + '-remark'"
          v-show="mminteration['closeDatetime'] === undefined"
          class="add-remark-btn-title"
        ></span>
        <Popup
          v-model="mminteration['remark']"
          :title-label="$t('editTrip.remarkTitle')"
          :maxlength="2000"
          :modalName="'mmiSuppSpeciesAtRiskEntry-' + index + '-remark'"
        />
      </h4>
      <b-overlay
        :show="mminteration['closeDatetime'] !== undefined"
        rounded="sm"
        blur="none"
        :key="'specie' + index + mminteration['closeDatetime']"
      >
        <template #overlay>
          <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
          <p class="redMessage">{{ $t("closeMsg") }}</p>
          <p class="redMessage">
            {{ $t("closeMsgDate")
            }}{{ showDate(mminteration["closeDatetime"]) }}
          </p>
        </template>
        <BasicInfo
          :addErrorToParent="
            error => {
              addError(error, index);
            }
          "
          :parentShowError="showErrorData + refreshIndex[index]"
          :mminteration="mminteration"
          :index="index"
        />
        <Details
          :addErrorToParent="
            error => {
              addError(error, index);
            }
          "
          :parentShowError="showErrorData + refreshIndex[index]"
          :mminteration="mminteration"
          :index="index"
        />
        <Incident
          :addErrorToParent="
            error => {
              addError(error, index);
            }
          "
          :parentShowError="showErrorData + refreshIndex[index]"
          :mminteration="mminteration"
          :index="index"
        />

        <identification
          :addErrorToParent="
            error => {
              addError(error, index);
            }
          "
          :parentShowError="showErrorData + refreshIndex[index]"
          :mminteration="mminteration"
          :index="index"
        />
      </b-overlay>
      <fieldset class="suppEntryButtonWrapper removeBottomPadding">
        <p>
          <input
            v-if="
              !mminteration['closeDatetime'] &&
                mminteration.hasMmi !== $const.NO
            "
            @click="closeMmiEvent(index)"
            class="closeSuppEntryButton"
            type="button"
            :class="
              index + 1 < form.interactions.length
                ? 'addBottomMargin'
                : 'removeBottomMargin'
            "
            :value="$t('editTrip.speciesAtRiskStep.close') + String(index + 1)"
          />
        </p>
      </fieldset>
    </fieldset>
    <fieldset class="suppEntryButtonWrapper removeTopMargin">
      <p>
        <input
          v-if="
            form.interactions &&
              form.interactions.length > 0 &&
              form.interactions[0].hasMmi !== $const.NO &&
              !$isLastItemClosed(form.interactions)
          "
          @click="removeMmiEvent()"
          class="removeSuppEntryButton"
          :disabled="form.interactions.length === 1"
          type="button"
          :value="
            $t('editTrip.speciesAtRiskStep.remove') +
              String(form.interactions.length)
          "
        />
        <input
          :href="'#interactions' + (form.interactions.length - 1)"
          @click="addMmiEvent"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.speciesAtRiskStep.add')"
        />
      </p>
    </fieldset>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import DateFormat from "@/utils/dateFormat.js";
import BasicInfo from "./EffortStepComponents/MmiStep/BasicInfo.vue";
import Details from "./EffortStepComponents/MmiStep/Details.vue";
import Incident from "./EffortStepComponents/MmiStep/Incident.vue";
import Identification from "./EffortStepComponents/MmiStep/Identification.vue";
import Popup from "@/components/widgets/Popup.vue";

const name = "mmiStep";

export default {
  components: {
    BasicInfo,
    Details,
    Incident,
    Popup,
    Identification
  },
  name: "MmiStep",
  data: () => ({
    form: {
      interactions: []
    },
    confirmationSar: false,
    confirmationMmi: false,
    firstTime: true,
    showErrorData: 0,
    refreshIndex: []
  }),
  computed: {
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      userProfile: state => state.userProfile,
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      currentPage: state => state.editTripSubform.currentPage,
      showError: state => state.editTripSubform.showError,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      stateErrors: state => state.editTripSubform.errors
    })
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === name) {
        ++this.showErrorData;
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    "openTrip.efforts": {
      deep: true,
      handler: function() {
        this.autoCreateMmiEntries();
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    addError(error, index) {
      this.addErrorsToState({ page: `${name}-${index}`, errors: error });
      this.addErrorsToState({ page: name, errors: error });
    },
    addMmiEvent() {
      this.showErrorData += 1;

      this.$nextTick(() => {
        const errorsFieldsNames = keysMatchingValue(
          this.stateErrors,
          true,
          `${name}-${this.form.interactions.length - 1}`
        );
        if (errorsFieldsNames.length > 0) {
          scrollToTopField(errorsFieldsNames);
          return;
        }
        if (this.form.interactions[0].hasMmi === this.$const.NO) {
          this.form.interactions.splice(-1, 1);
        }
        this.form.interactions.push(this.emptyMmi());
        this.refreshIndex.push(0);
      });
    },
    removeMmiEvent() {
      if (!this.checkIfObjectClosed(this.form.interactions)) {
        // splice the last element only
        this.form.interactions.splice(-1, 1);
        this.refreshIndex.pop();
        if (this.form.interactions.length === 0) {
          this.form.interactions.push(this.emptyMmi(false, true));
          this.refreshIndex.push(0);
        }
      }
    },
    async closeMmiEvent(index) {
      Vue.set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      this.closeObject({
        value: this.form.interactions,
        type: `${name}-${index}`,
        index,
        vm: this
      });
    },
    autoCreateMmiEntries() {
      /*if (
        this.form.interactions.length === 0 ||
        this.form.interactions[0].hasMmi === this.$const.NO
      ) {
        let firstTime = true;
        this.openTrip.efforts?.forEach(effort => {
          if (effort.confirmationMmi === this.$const.YES) {
            if (firstTime) {
              this.form.interactions = [];
              firstTime = false;
            }
            this.form.interactions.push(this.emptyMmi(effort.endPosition));
          }
        });
      }*/
      if (this.form.interactions.length === 0) {
        this.form.interactions.push(this.emptyMmi());
      }
    },
    emptyMmi(position, noMmi) {
      let mmi = {
        remark: null,
        hasMmi: this.$const.YES, //client usage only
        creation: new Date().toISOString(),
        occursAt: {
          autolat: null,
          autolon: null,
          datetime: null,
          latitude: null,
          longitude: null
        },
        context: {
          name: this.userProfile.userfullname,
          address: this.userProfile.address,
          province: this.userProfile.province,
          email: this.userProfile.email,
          phone: this.userProfile.phoneNumber
        },
        incidentTypes: [{}],
        species: null,
        specimenCondition: null,
        nbSpecimen: null,
        weight: null
      };
      if (position) {
        mmi.occursAt.autolat = position.autolat;
        mmi.occursAt.autolon = position.autolon;
        mmi.occursAt.latitude = position.latitude;
        mmi.occursAt.longitude = position.longitude;
      }
      if (noMmi) {
        mmi.hasMmi = this.$const.NO;
        //mmi.creation = new Date().toISOString();
        mmi.incidentTypes = null;
      }
      return mmi;
    },
    checkIfObjectClosed(value) {
      if (Array.isArray(value)) {
        if (value[value.length - 1].closeDatetime !== undefined) {
          return true;
        }
        return false;
      }
    },
    updateForm() {
      if (this.openTrip["creation"] && this.openTrip !== null) {
        this.form.interactions = this.openTrip.interactions;
      }
    },
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
      //return DateFormat.toStringDate(new Date(this.form['creation']),"yyyy-mm-dd HH:MM")
    }
  },
  beforeMount() {
    if (this.openTrip.interactions && this.openTrip.interactions.length > 0) {
      this.form.interactions = this.openTrip.interactions;
    } else {
      this.autoCreateMmiEntries();
    }
    this.refreshIndex = Array(this.form.interactions.length).fill(0);
  }
};
</script>
