<template>
  <fieldset class="gearInfo removeTopMargin" :name="'Gear Info ' + (index + 1)">
    <h4 class="level2 sticky">
      <span
        v-if="!isSetting"
        v-b-modal="'gearInfo-' + index + '-remark'"
        class="add-remark-btn-title"
      >
      </span>
      {{ $t("editTrip.gearInfo.subtitle") }} {{ index + 1 }}
    </h4>
    <Popup
      v-model="gearInfo['remark']"
      :title-label="$t('editTrip.remarkTitle')"
      :maxlength="2000"
      :modalName="'gearInfo-' + index + '-remark'"
    />
    <Select
      v-if="gearInfoMode === $const.GEAR_INFO_CRAB"
      v-model="gearInfo.gearType"
      :id="'gearType ' + index"
      :label="$t('editTrip.gearInfo.gearType')"
      :options="gearInfoTypeOptions"
      :required="true"
      :refeshError="refeshError"
      @error="checkError"
    />
    <IntegerInput
      v-if="gearInfoMode === $const.GEAR_INFO_SHRIMP"
      v-model="gearInfo.nbTunnels"
      :id="'nbTunnels ' + index"
      :label="$t('editTrip.gearInfo.nbTunnels')"
      :min="1"
      :max="9"
      :required="true"
      :refeshError="refeshError"
      @error="checkError"
    />
    <IntegerInput
      v-model="gearInfo.nbGear"
      :id="'nbGears ' + index"
      :label="$t('fields.nbGears')"
      :required="true"
      :min="1"
      :max="9999"
      :refeshError="refeshError"
      @error="checkError"
    />
    <Select
      v-if="gearInfoMode === $const.GEAR_INFO_CRAB"
      v-model="gearInfo.frameMaterial"
      :id="'frameMaterial ' + index"
      :label="$t('editTrip.gearInfo.frameMaterial')"
      :options="frameMaterialOptions"
      :required="true"
      :refeshError="refeshError"
      @error="checkError"
    />
    <UnitConverter
      v-if="hasGearBottomDiameter"
      v-model="gearInfo.bottomDiameter"
      :id="'bottomDiameter ' + index"
      :label="$t('editTrip.gearInfo.bottomDiameter')"
      :min="1"
      :max="274"
      :required="hasGearBottomDiameter === $const.MANDATORY"
      :refresh="refeshError"
      unit="cm"
      @error="checkError"
    />
    <UnitConverter
      v-if="gearInfoMode === $const.GEAR_INFO_SHRIMP"
      v-model="gearInfo.topDiameter"
      :id="'topDiameter ' + index"
      :label="$t('editTrip.gearInfo.topDiameter')"
      :min="0"
      :max="9999"
      :required="false"
      :refresh="refeshError"
      unit="cm"
      @error="checkError"
    />
    <UnitConverter
      v-if="gearInfoMode === $const.GEAR_INFO_SHRIMP"
      v-model="gearInfo.length"
      :id="'length ' + index"
      :label="$t('editTrip.gearInfo.length')"
      :min="0"
      :max="9999.99"
      :required="false"
      :refresh="refeshError"
      unit="m"
      @error="checkError"
    />
    <UnitConverter
      v-if="hasGearWidth"
      v-model="gearInfo.width"
      :id="'width ' + index"
      :label="$t('editTrip.gearInfo.width')"
      :min="1"
      :max="274"
      :required="hasGearWidth === $const.MANDATORY"
      :refresh="refeshError"
      unit="cm"
      @error="checkError"
    />
    <UnitConverter
      v-model="gearInfo.height"
      :label="$t('editTrip.gearInfo.height')"
      :id="'height ' + index"
      :min="1"
      :max="9999"
      :required="true"
      :refresh="refeshError"
      unit="cm"
      @error="checkError"
    />
    <Select
      v-if="gearInfoMode === $const.GEAR_INFO_SHRIMP"
      v-model="gearInfo.trapSubType"
      :id="'trapSubType ' + index"
      :label="$t('editTrip.gearInfo.trapSubType')"
      :options="trapSubtypeOptions"
      :required="true"
      :refeshError="refeshError"
      @error="checkError"
    />
    <Select
      v-if="gearInfoMode === $const.GEAR_INFO_CRAB"
      v-model="gearInfo.meshMaterial"
      :id="'meshMaterial ' + index"
      :label="$t('editTrip.gearInfo.meshMaterial')"
      :options="meshMaterialOptions"
      :required="true"
      :refeshError="refeshError"
      @error="checkError"
    />
    <UnitConverter
      v-if="gearInfoMode === $const.GEAR_INFO_CRAB"
      v-model="gearInfo.escapeDiameter"
      :id="'escapeDiameter ' + index"
      :label="$t('editTrip.gearInfo.escapeDiameter')"
      :min="minGearEscapeDiameter"
      :max="maxGearEscapeDiameter"
      :required="true"
      :refresh="refeshError"
      unit="mm"
      @error="checkError"
    />
  </fieldset>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import Select from "@/components/Select.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  name: "GearInfo",
  components: {
    Select,
    IntegerInput,
    Popup,
    UnitConverter
  },
  props: {
    checkError: Function,
    refeshError: Number,
    gearInfo: Object,
    index: Number,
    isSetting: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      gearTypes: state => state.systemsLists.gearType,
      gearSubtypes: state => state.systemsLists.gearSubTypes,
      materialTypes: state => state.systemsLists.materialTypes,
      subform: state => state.editTripSubform.subform
    }),
    gearInfoTypes() {
      return this.getPropertyValue("gearInfoTypes", {}, []);
    },
    gearInfoTypeOptions() {
      return this.buildOptions(this.gearInfoTypes, this.gearTypes);
    },
    trapSubtypes() {
      return this.getPropertyValue(
        "trapSubtypes",
        { subforms: this.subform },
        []
      );
    },
    trapSubtypeOptions() {
      return this.buildOptions(this.trapSubtypes, this.gearSubtypes);
    },
    frameMaterials() {
      return this.getPropertyValue("frameMaterials", {}, []);
    },
    frameMaterialOptions() {
      return this.buildOptions(this.frameMaterials, this.materialTypes);
    },
    meshMaterials() {
      return this.getPropertyValue("meshMaterials", {}, []);
    },
    meshMaterialOptions() {
      return this.buildOptions(this.meshMaterials, this.materialTypes);
    },
    hasGearBottomDiameter() {
      return this.getPropertyValue("hasGearBottomDiameter", {
        subforms: this.subform,
        gears: this.gearInfo.gearType
      });
    },
    hasGearWidth() {
      return this.getPropertyValue("hasGearWidth", {
        subforms: this.subform,
        gears: this.gearInfo.gearType
      });
    },
    gearInfoMode() {
      return this.getPropertyValue("gearInfoMode", { subforms: this.subform });
    },
    minGearEscapeDiameter() {
      return this.getPropertyValue("minGearEscapeDiameter");
    },
    maxGearEscapeDiameter() {
      return this.getPropertyValue("maxGearEscapeDiameter");
    }
  },
  watch: {
    subform() {
      this.updateVisibility();
    },
    "gearInfo.gearType"() {
      this.updateVisibility();
    }
  },
  methods: {
    updateVisibility() {
      if (this.gearInfo) {
        this.updateHasGearWidth();
        this.updateHasGearBottomDiameter();
      }
    },
    updateHasGearWidth() {
      if (this.hasGearWidth === this.$const.BLOCKED) {
        delete this.gearInfo.width;
      }
    },
    updateHasGearBottomDiameter() {
      if (this.hasGearBottomDiameter === this.$const.BLOCKED) {
        delete this.gearInfo.bottomDiameter;
      }
    }
  },
  mounted() {
    this.updateVisibility();
  }
};
</script>
