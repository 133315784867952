var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('h3',{staticClass:"step-title"},[_c('span',[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.gearLossStep.title")))])])])]),_vm._l((_vm.form.gearLossEvents),function(loss,index){return _c('fieldset',{key:index,staticClass:"removeBottomPadding"},[(_vm.form.gearLossEvents.length > 0)?_c('h4',{staticClass:"level1"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.gearLossStep.loss"))+" "+_vm._s(index + 1)+" "),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('suppGearLossEntry-' + index + 1 + '-geotemporal-remark'),expression:"'suppGearLossEntry-' + index + 1 + '-geotemporal-remark'"},{name:"show",rawName:"v-show",value:(loss['closeDatetime'] === undefined),expression:"loss['closeDatetime'] === undefined"}],staticClass:"add-remark-btn-title"}),_c('Popup',{attrs:{"title-label":_vm.$t('editTrip.remarkTitle'),"maxlength":2000,"modalName":'suppGearLossEntry-' + index + 1 + '-geotemporal-remark'},model:{value:(loss['remark']),callback:function ($$v) {_vm.$set(loss, 'remark', $$v)},expression:"loss['remark']"}})],1):_vm._e(),_c('b-overlay',{key:'loss' + index + loss['closeDatetime'],attrs:{"show":loss['closeDatetime'] !== undefined,"rounded":"sm","blur":"none"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-icon',{attrs:{"icon":"file-lock","variant":"danger","scale":"2"}}),_c('p',{staticClass:"redMessage"},[_vm._v(_vm._s(_vm.$t("closeMsg")))]),_c('p',{staticClass:"redMessage"},[_vm._v(" "+_vm._s(_vm.$t("closeMsgDate"))+_vm._s(_vm.showDate(loss["closeDatetime"]))+" ")])]},proxy:true}],null,true)},[_c('fieldset',{attrs:{"disabled":loss['closeDatetime'] !== undefined}},[_c('DateInput',{attrs:{"hideTime":true,"label":_vm.$t('editTrip.gearLossStep.repDate'),"required":true,"passDate":true,"autodatetime":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['creation']),callback:function ($$v) {_vm.$set(loss, 'creation', $$v)},expression:"loss['creation']"}}),_c('Select',{attrs:{"label":_vm.$t('editTrip.gearLossStep.status'),"options":_vm.$t('gearStatusOptions.items'),"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['status']),callback:function ($$v) {_vm.$set(loss, 'status', $$v)},expression:"loss['status']"}}),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('br'):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('GenericInput',{key:'gearType-' + index + '-fin',attrs:{"label":_vm.$t('fields.fishingLicence'),"type":"text","required":true,"minlength":4,"maxlength":_vm.$const.licenceMaxLength,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['fishingLicenceNumber']),callback:function ($$v) {_vm.$set(loss, 'fishingLicenceNumber', (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"loss['fishingLicenceNumber']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('Select',{key:'gearType-' + index + '-target',attrs:{"label":_vm.$t('fields.targetSpecie'),"options":_vm.targetSpecies,"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['target']),callback:function ($$v) {_vm.$set(loss, 'target', $$v)},expression:"loss['target']"}}):_vm._e(),(loss['status'] && _vm.isLostSelected(loss['status']))?_c('Select',{key:'gearType-' + index + '-area',attrs:{"label":_vm.$t('fields.area'),"options":_vm.fishingAreaOptions,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['area']),callback:function ($$v) {_vm.$set(loss, 'area', $$v)},expression:"loss['area']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('DateInput',{key:'lostDate-' + index + loss['status'],attrs:{"hideTime":true,"label":_vm.$t('editTrip.gearLossStep.lostDate'),"required":true,"passDate":true,"autodatetime":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss.occursAt['datetime']),callback:function ($$v) {_vm.$set(loss.occursAt, 'datetime', $$v)},expression:"loss.occursAt['datetime']"}}):_vm._e(),(
            loss['status'] &&
              !_vm.isNoGearLostSelected(loss['status']) &&
              !_vm.isLostSelected(loss['status'])
          )?_c('DateInput',{key:'recoveryDate-' + index + loss['status'],attrs:{"hideTime":true,"label":_vm.$t('editTrip.gearLossStep.recoveryDate'),"required":true,"passDate":true,"autodatetime":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['recovery']),callback:function ($$v) {_vm.$set(loss, 'recovery', $$v)},expression:"loss['recovery']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('Select',{key:'gearType2-' + index + loss['status'],attrs:{"label":_vm.$t('editTrip.gearLossStep.gearType'),"options":_vm.lostGearTypes,"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['gearType']),callback:function ($$v) {_vm.$set(loss, 'gearType', $$v)},expression:"loss['gearType']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('br'):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('GeopositionWidget',{key:'latitude-' + index + loss['status'],attrs:{"position":loss.occursAt,"required":true,"minLat":[38.0],"maxLat":[72.0],"minLong":[-148.0],"maxLong":[-40.0],"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"binding":function changeValue(value) {
              loss.occursAt = { ...loss.occursAt, ...value };
            },"error":error => {
              _vm.addError(error, index);
            }}}):_vm._e(),(
            _vm.isLostSelected(loss['status']) &&
              _vm.isDirectionEnabled(loss['gearType'])
          )?_c('Select',{key:'direction-' + index + loss['status'],attrs:{"label":_vm.$t('editTrip.gearLossStep.direction'),"options":_vm.$t('mvCardinalPoint.items'),"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['direction']),callback:function ($$v) {_vm.$set(loss, 'direction', $$v)},expression:"loss['direction']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('br'):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('IntegerInput',{key:'nbGear-' + index + loss['status'],attrs:{"label":_vm.$t('editTrip.gearLossStep.nbGear'),"required":true,"min":1,"max":9999,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['nbGear']),callback:function ($$v) {_vm.$set(loss, 'nbGear', $$v)},expression:"loss['nbGear']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('br'):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('GenericInput',{key:'gearDesc-' + index + loss['status'],attrs:{"label":_vm.$t('editTrip.gearLossStep.gearDesc'),"type":"text","maxlength":150,"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['gearDesc']),callback:function ($$v) {_vm.$set(loss, 'gearDesc', $$v)},expression:"loss['gearDesc']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('UnitConverter',{key:'ropeLength-' + index + loss['status'],attrs:{"label":_vm.$t('editTrip.gearLossStep.ropeLength'),"required":true,"min":0,"unit":"m","max":150000,"refresh":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['ropeLength']),callback:function ($$v) {_vm.$set(loss, 'ropeLength', $$v)},expression:"loss['ropeLength']"}}):_vm._e(),(
            loss['status'] &&
              !_vm.isNoGearLostSelected(loss['status']) &&
              [905, 1089, 1091, 1105, 1101, 1105].includes(loss['gearType'])
          )?_c('UnitConverter',{key:'netLength-' + index + loss['status'] + loss['gearType'],attrs:{"label":_vm.$t('editTrip.gearLossStep.netLength'),"unit":"m","required":true,"min":0,"max":99999.999,"refresh":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['netLength']),callback:function ($$v) {_vm.$set(loss, 'netLength', $$v)},expression:"loss['netLength']"}}):_vm._e(),(
            loss['status'] &&
              !_vm.isNoGearLostSelected(loss['status']) &&
              [905, 914, 925, 1105].includes(loss['gearType'])
          )?_c('IntegerInput',{key:'nbBuoys-' + index + loss['status'] + loss['gearType'],attrs:{"label":_vm.$t('editTrip.gearLossStep.nbBuoys'),"required":true,"min":0,"max":1500,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['nbBuoys']),callback:function ($$v) {_vm.$set(loss, 'nbBuoys', $$v)},expression:"loss['nbBuoys']"}}):_vm._e(),(
            loss['status'] &&
              !_vm.isNoGearLostSelected(loss['status']) &&
              loss['nbBuoys'] > 0 &&
              [905, 914, 925, 1105].includes(loss['gearType'])
          )?_c('GenericInput',{key:'buoyDesc-' + index + loss['status'] + loss['nbBuoys'],attrs:{"label":_vm.$t('editTrip.gearLossStep.buoyDesc'),"type":"text","maxlength":150,"required":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['buoyDesc']),callback:function ($$v) {_vm.$set(loss, 'buoyDesc', $$v)},expression:"loss['buoyDesc']"}}):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('br'):_vm._e(),(loss['status'] && !_vm.isNoGearLostSelected(loss['status']))?_c('GenericInput',{key:'tags-' + index + loss['nbGear'],attrs:{"label":_vm.$t('editTrip.gearLossStep.tags'),"help":_vm.$t('editTrip.gearLossStep.tagsHelp'),"type":"tags","max":loss['nbGear'],"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(loss['tags']),callback:function ($$v) {_vm.$set(loss, 'tags', $$v)},expression:"loss['tags']"}}):_vm._e()],1)]),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeBottomPadding"},[(!loss['closeDatetime'])?_c('input',{staticClass:"closeSuppEntryButton",class:index + 1 < _vm.form.gearLossEvents.length
            ? 'addBottomMargin'
            : 'removeBottomMargin',attrs:{"type":"button","value":_vm.$t('editTrip.gearLossStep.closeGear') + String(index + 1)},on:{"click":function($event){return _vm.closeLossEvent(index)}}}):_vm._e()])],1)}),_c('fieldset',{staticClass:"suppEntryButtonWrapper removeTopMargin"},[_c('div',[(
          _vm.form.gearLossEvents.length > 1 &&
            !_vm.$isLastItemClosed(_vm.form.gearLossEvents)
        )?_c('input',{staticClass:"removeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.gearLossStep.removeGear') +
            String(_vm.form.gearLossEvents.length)},on:{"click":function($event){return _vm.removeLossEvent()}}}):_vm._e(),_c('input',{staticClass:"addSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.gearLossStep.addGear')},on:{"click":function($event){return _vm.addLossEvent()}}})])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }