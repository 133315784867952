<template>
  <fieldset>
    <h4 class="level1">{{ $t("section.operator") }}</h4>
    <GenericInput
      v-if="hasName"
      v-model="form.operator.name"
      :label="getNameText"
      :required="hasName === $const.MANDATORY"
      :maxlength="80"
      :options="suboperators"
      :otherValue="true"
      :refeshError="refreshError"
      type="datalist"
      @binding="
        value => {
          if (hasGeneralInfoFin !== $const.BLOCKED)
            form.operator.fisherIdentificationNumber = value[1];
        }
      "
      @error="checkError"
    />
    <GenericInput
      v-if="hasGeneralInfoFin"
      v-model="form.operator.fisherIdentificationNumber"
      :label="$t('editTrip.subscriptionStep.operatorFin')"
      :help="$t('editTrip.subscriptionStep.operatorFinHelp')"
      :required="hasGeneralInfoFin === $const.MANDATORY"
      :finValidator="true"
      :otherValue="true"
      :refeshError="refreshError"
      type="datalist"
      @binding="
        value => {
          if (hasName !== $const.BLOCKED) form.operator.name = value[1];
        }
      "
      @error="checkError"
    />
    <GenericInput
      v-if="hasOperatorMailAndAddress"
      v-model="form.operator.email"
      :label="$t('fields.email')"
      :required="hasOperatorMailAndAddress === $const.MANDATORY"
      :maxlength="50"
      :refeshError="refreshError"
      type="email"
      @error="checkError"
    />
    <GenericInput
      v-if="hasOperatorMailAndAddress"
      v-model="form.operator.address"
      :label="$t('fields.address')"
      :required="hasOperatorMailAndAddress === $const.MANDATORY"
      :maxlength="200"
      :refeshError="refreshError"
      type="text"
      @error="checkError"
    />
    <GenericInput
      v-if="hasPhone"
      v-model="form.operator.phoneNumber"
      :label="getPhoneText"
      :required="hasPhone === $const.MANDATORY"
      :maxlength="25"
      :refeshError="refreshError"
      type="phone"
      @error="checkError"
    />
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Operator",
  components: {
    GenericInput
  },
  props: {
    checkError: Function,
    refreshError: Number,
    form: Object
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subform: state => state.editTripSubform.subform,
      suboperators: state => state.editTripSubform.suboperators
    }),
    hasGeneralInfoFin() {
      return this.getPropertyValue("hasGeneralInfoFin", {
        subforms: this.subform
      });
    },
    hasOperatorName() {
      return this.getPropertyValue("hasOperatorName", {
        subforms: this.subform
      });
    },
    hasHarvesterName() {
      return this.getPropertyValue("hasHarvesterName", {
        subforms: this.subform
      });
    },
    hasCaptainName() {
      return this.getPropertyValue("hasCaptainName", {
        subforms: this.subform
      });
    },
    getNameText() {
      let nameText = this.$t("fields.operatorName");
      if (this.hasHarvesterName) {
        nameText = this.$t("fields.harvesterName");
      } else if (this.hasCaptainName) {
        nameText = this.$t("fields.captainName");
      }
      return nameText;
    },
    hasName() {
      return this.hasOperatorName === this.$const.MANDATORY ||
        this.hasHarvesterName === this.$const.MANDATORY ||
        this.hasCaptainName === this.$const.MANDATORY
        ? this.$const.MANDATORY
        : this.hasOperatorName === this.$const.OPTIONAL ||
          this.hasHarvesterName === this.$const.OPTIONAL ||
          this.hasCaptainName === this.$const.OPTIONAL
        ? this.$const.OPTIONAL
        : this.$const.BLOCKED;
    },
    hasOperatorPhone() {
      return this.getPropertyValue("hasOperatorPhone", {
        subforms: this.subform
      });
    },
    hasContactPhone() {
      return this.getPropertyValue("hasContactPhone", {
        subforms: this.subform
      });
    },
    getPhoneText() {
      let phoneText = this.$t("fields.phone");
      if (this.hasContactPhone) {
        phoneText = this.$t("fields.contactPhone");
      }
      return phoneText;
    },
    hasPhone() {
      return this.hasOperatorPhone === this.$const.MANDATORY ||
        this.hasContactPhone === this.$const.MANDATORY
        ? this.$const.MANDATORY
        : this.hasOperatorPhone === this.$const.OPTIONAL ||
          this.hasContactPhone === this.$const.OPTIONAL
        ? this.$const.OPTIONAL
        : this.$const.BLOCKED;
    },
    hasOperatorMailAndAddress() {
      return this.getPropertyValue("hasOperatorMailAndAddress", {
        subforms: this.subform
      });
    }
  },
  watch: {
    hasGeneralInfoFin: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.operator.fisherIdentificationNumber;
        }
      }
    },
    hasName: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.operator.name;
        }
      }
    },
    hasPhone: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.operator.phoneNumber;
        }
      }
    },
    hasOperatorMailAndAddress: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.operator.email;
          delete this.form.operator.address;
        }
      }
    }
  }
};
</script>
