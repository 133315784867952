<template>
  <div>
    <p v-show="!order" class="gen-txtCenter">
      {{ $t("lobby.noorder") }}
    </p>
    <p v-show="isDeclined" class="serverSaveError error">
      {{ $t("lobby.declined") }}
    </p>
    <div v-if="daysRemaining > 0" class="topContentWarning">
      {{ $tc("lobby.daysRemaining", daysRemaining) }}
      <br />
      {{ $t("lobby.addOneYear") }}
    </div>
    <table v-if="order" class="table table-borderless">
      <thead>
        <tr>
          <th colspan="2" class="text-center">
            {{ $t("lobby.order") }}&nbsp;{{ order.invoiceNumber }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">{{ $t("date") }}</th>
          <td>{{ order.datetime | day }}&nbsp;{{ order.datetime | time }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t("lobby.buyer") }}</th>
          <td>{{ order.userName }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t("lobby.module") }}</th>
          <td>{{ order.description }}</td>
        </tr>
        <tr>
          <th scope="row">{{ $t("lobby.pay") }}</th>
        </tr>
        <tr>
          <td>{{ $t("lobby.item") }}</td>
          <td>{{ order.amount }}&nbsp;$</td>
        </tr>
        <tr>
          <td>{{ $t("lobby.tax") }}</td>
          <td>{{ order.taxes }}&nbsp;$</td>
        </tr>
        <tr>
          <th scope="row">{{ $t("lobby.total") }}</th>
          <td class="total">
            <span>{{ order.total }}&nbsp;$</span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="note">
            <span>
              <span class="font-weight-bold">
                {{ $t("lobby.note") }}
              </span>
              {{
                $t("lobby.message", {
                  date: $d(order.newDate, "long")
                })
              }}
            </span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="text-center">
            <button
              v-show="showActionButtons"
              :class="!isConnectedOnline && 'deactivated'"
              id="stripe-button-el"
              @click="doOrder()"
            >
              <span>{{ $t("lobby.payment") }}</span>
            </button>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="text-center">
            <a
              class="cancelOrder"
              @click.stop="cancel()"
              v-show="showActionButtons"
            >
              {{ $t("lobby.cancel") }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import LOBBY_ICONS from "@/utils/lobby_icons";
import { mapActions } from "vuex";

export default {
  name: "modulesorder",
  computed: {
    showActionButtons() {
      return this.order && !this.order.isPaid && this.stripe.loaded;
    },
    isConnectedOnline() {
      return this.$store.getters.IS_APP_CONNECTED_ONLINE;
    },
    daysRemaining() {
      let numberOfDaysRemaining = 0;
      if (this.order.newDate) {
        const expire = this.order.newDate;
        var nextYear = new Date();
        nextYear.setFullYear(nextYear.getFullYear() + 1);
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        numberOfDaysRemaining = Math.round(
          (expire.getTime() - nextYear.getTime()) / millisecondsPerDay
        );
      }
      return numberOfDaysRemaining;
    }
  },
  data() {
    return {
      isDeclined: false,
      order: {},
      stripe: {
        loaded: false,
        handler: null,
        token: null,
        email: null
      }
    };
  },
  methods: {
    ...mapActions("api", ["getOrder", "processOrder", "cancelOrder"]),
    getImageData: function(name) {
      if (!name) return "";
      const language = this.$store.getters.LANGUAGE;
      return LOBBY_ICONS[name.format(language)];
    },
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    },
    doOrder() {
      if (!this.isConnectedOnline) {
        this.onlinePopupError();
        return;
      }
      this.stripe.handler.open({
        name: "Jobel",
        description: this.order.dataDescription,
        amount: this.order.dataAmount,
        currency: "cad",
        locale: this.$store.getters.LANGUAGE
      });
    },
    process() {
      var vuejs = this;
      vuejs.$store
        .dispatch("showLoadingOverlay")
        .then(() =>
          vuejs.processOrder({
            order: this.order.order_uuid,
            token: this.stripe.token,
            email: this.stripe.email
          })
        )
        .then(function(subscription) {
          if (subscription) {
            vuejs.isDeclined = false;
            return vuejs.$store
              .dispatch("updateSubscriptions")
              .then(() => vuejs.$router.replace({ name: "modules" }));
          } else {
            vuejs.isDeclined = true;
          }
        })
        .catch(function(error) {
          vuejs.$alertUserApiError(error);
          vuejs.isDeclined = true;
        })
        .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
    },
    cancel() {
      var vuejs = this;
      vuejs.$store
        .dispatch("showLoadingOverlay")
        .then(() => vuejs.cancelOrder(vuejs.order.order_uuid))
        .finally(() => {
          vuejs.$store.dispatch("hideLoadingOverlay");
          vuejs.$router.replace({ name: "modules" }); // dont need to wait
        });
    }
  },
  mounted() {
    var vuejs = this;
    if (this.$route.params && this.$route.params.id) {
      vuejs
        .getOrder(vuejs.$route.params.id)
        .then(function(order) {
          vuejs.order = order;
          vuejs.order.datetime = new Date(order.datetime);
          vuejs.order.newDate = new Date(order.newDate);

          if (order.isPaid) {
            return vuejs.$store
              .dispatch("updateSubscriptions")
              .then(() => vuejs.$router.replace({ name: "modules" }));
          } else {
            // pris de vue-plugin-load-script
            let el = document.querySelector(
              'script[src="https://checkout.stripe.com/checkout.js"]'
            );
            if (!el) {
              el = document.createElement("script");
              el.type = "text/javascript";
              el.async = true;
              el.src = "https://checkout.stripe.com/checkout.js";
              el.onload = function() {
                vuejs.stripe.loaded = true;
                vuejs.stripe.handler = StripeCheckout.configure({
                  key: vuejs.order.stripeKey,
                  image: "/img/rppsg_logo_stripe.png",
                  token: function(token) {
                    vuejs.stripe.token = token.id;
                    vuejs.stripe.email = token.email;
                    vuejs.process();
                  }
                });
              };
              document.head.appendChild(el);
            } else if (vuejs.order) {
              vuejs.stripe.loaded = true;
              vuejs.stripe.handler = StripeCheckout.configure({
                key: vuejs.order.stripeKey,
                image: "/img/rppsg_logo_stripe.png",
                token: function(token) {
                  vuejs.stripe.token = token.id;
                  vuejs.stripe.email = token.email;
                  vuejs.process();
                }
              });
            }
          }
        })
        .catch(error => vuejs.$alertUserApiError(error))
        .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 30px;
  background: white;
}
.total {
  // padding-top: 10px;
  span {
    padding-top: 10px;
    border-top: 2px dashed $darkColor;
  }
}
.note {
  padding: 20px;
  // padding-top: 20px;
  > span {
    border: 1px dotted gray;
    padding: 10px;
    display: block;
  }
}
ul {
  li {
    list-style: none;
    margin-bottom: 30px;
    background: white;

    h2 {
      color: black;
      margin-top: 0px;
      margin-bottom: 40px;
    }
    & > div,
    & > p {
      margin: 25px 0px;
      & > span.label {
        font-weight: bold;
      }
    }
    & > div {
      & > p {
        margin: 25px 0px;
      }
      span.amount {
        position: absolute;
        left: 220px;
      }
      .total span.label {
        font-weight: bold;
      }
    }
  }
}

#stripe-button-el {
  max-width: 100%;
  border: none;
  min-height: 53px;
  width: 290px;
  background-color: $lightColor;
  font-size: 1em;
  color: white;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  position: relative;
  top: 0;
  &.deactivated {
    background-color: gray;
    text-decoration: none;

    &:hover,
    &:active {
      background-color: gray;
      text-decoration: none;
    }
  }
}
button:hover {
  background-color: $darkColor;
}
button:active {
  background-color: gray;
}
button:disabled {
  background-color: gray;
  cursor: default;
}
</style>
