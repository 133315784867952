<template>
  <div id="">
    <Summary v-if="type === 'hailIn'" />
    <div>
      <fieldset
        v-for="(hail, index) in hails"
        :key="'hail-' + type + index"
        class="removeBottomPadding"
      >
        <h4 class="level1">
          {{ $t("editTrip." + type + "Call") }}&nbsp;{{ index + 1 }}
          <span
            v-b-modal="'modal-' + type + '-remark' + index"
            class="add-remark-btn-title"
            v-show="hail['closeDatetime'] === undefined"
          ></span>
          <Popup
            v-model="hail['remark']"
            :title-label="$t('editTrip.remarkTitle')"
            :maxlength="2000"
            :modalName="'modal-' + type + '-remark' + index"
          />
        </h4>
        <b-overlay
          :show="hail['closeDatetime'] !== undefined"
          rounded="sm"
          blur="none"
          :key="'hail' + type + index + hail['closeDatetime']"
        >
          <template #overlay>
            <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
            <p class="redMessage">{{ $t("closeMsg") }}</p>
            <p class="redMessage">
              {{ $t("closeMsgDate") }}{{ showDate(hail["closeDatetime"]) }}
            </p>
          </template>
          <fieldset
            v-if="type === 'hailOut'"
            :disabled="hail['closeDatetime'] !== undefined"
            class="removeBottomPadding"
          >
            <Select
              v-if="hasHailOutCompany"
              :label="$t('editTrip.departureStep.provider')"
              v-model="hail['provider']"
              :options="hailOutCallProviders"
              :required="hasHailOutCompany === $const.MANDATORY"
              @error="
                error => {
                  addErrors(error, index);
                }
              "
              :refeshError="refreshError + hailRefresh + refreshIndex[index]"
            />
            <GenericInput
              :label="$t('editTrip.hailOutNumber')"
              v-model="hail['number']"
              type="text"
              :maxlength="20"
              :required="true"
              @error="
                error => {
                  addErrors(error, index);
                }
              "
              :refeshError="refreshError + hailRefresh + refreshIndex[index]"
            />
            <fieldset class="suppEntryButtonWrapper removeBottomPadding">
              <p>
                <input
                  v-if="!hail['closeDatetime']"
                  @click="closeHail(index)"
                  class="closeSuppEntryButton removeTopMargin"
                  type="button"
                  :value="
                    $t('editTrip.hailOutCallCloseButton') + String(index + 1)
                  "
                />
              </p>
            </fieldset>
          </fieldset>
          <fieldset
            v-else
            :disabled="hail['closeDatetime'] !== undefined"
            class="removeBottomPadding"
          >
            <Select
              v-if="hasHailInCompany"
              :label="$t('editTrip.provider')"
              v-model="hail['provider']"
              :options="hailInCallProviders"
              :required="hasHailInCompany === $const.MANDATORY"
              @error="
                error => {
                  addErrors(error, index);
                }
              "
              :refeshError="refreshError + hailRefresh + refreshIndex[index]"
            />
            <GenericInput
              :label="$t('editTrip.hailInNumber')"
              v-model="hail['number']"
              type="text"
              :help="$t('editTrip.hailInMessage')"
              :maxlength="20"
              :required="true"
              @error="
                error => {
                  addErrors(error, index);
                }
              "
              :refeshError="refreshError + hailRefresh + refreshIndex[index]"
            />
            <RadioInput
              v-if="hasFishingCompleted"
              v-model="hail['isFishingCompleted']"
              :label="$t('editTrip.hailInfishing')"
              :required="true"
              :options="systemsLists.response"
              @error="
                error => {
                  addErrors(error, index);
                }
              "
              :refeshError="refreshError + hailRefresh + refreshIndex[index]"
            />
            <DateInput
              v-if="hasHailInEstimates"
              :label="$t('editTrip.lastStep.eta')"
              v-model="hail['estimatedArrival']"
              :required="true"
              :autodatetime="false"
              :minDates="[lastDateValue]"
              :passDate="false"
              @error="
                error => {
                  addErrors(error, index);
                }
              "
              :refeshError="refreshError + hailRefresh + refreshIndex[index]"
            />
            <UnitConverter
              v-if="hasHailInEstimates"
              :label="$t('editTrip.lastStep.weightOnBoard')"
              v-model="hail['weightOnBoard']"
              :required="true"
              :min="0"
              unit="weight"
              @error="
                error => {
                  addErrors(error, index);
                }
              "
              :refresh="refreshError + hailRefresh + refreshIndex[index]"
            />
            <HailInDetails
              v-if="hasHailInDetails"
              :showErrorDataParent="
                refreshError + hailRefresh + refreshIndex[index]
              "
              :addErrorToParent="
                error => {
                  addErrors(error, index);
                }
              "
              :details="hail['details']"
            />
            <fieldset
              v-if="hail['closeDatetime'] === undefined"
              class="suppEntryButtonWrapper removeBottomPadding"
              style="display: block;"
            >
              <p>
                <input
                  v-if="!hail['closeDatetime']"
                  @click="closeHail(index)"
                  class="closeSuppEntryButton"
                  type="button"
                  :value="
                    $t('editTrip.hailInCallCloseButton') + String(index + 1)
                  "
                />
              </p>
            </fieldset>
          </fieldset>
        </b-overlay>
      </fieldset>
      <fieldset
        class="suppEntryButtonWrapper removeTopMargin removeBottomPadding"
      >
        <p>
          <input
            v-if="hails !== null && hails.length > 1 && !checkCloseDate"
            @click="removeHail()"
            class="removeSuppEntryButton removeTopMargin"
            type="button"
            :value="
              (type === 'hailIn'
                ? $t('editTrip.hailInCallRemoveButton')
                : $t('editTrip.hailOutCallRemoveButton')) + String(hails.length)
            "
          />
          <input
            @click="addHail()"
            class="addSuppEntryButton"
            type="button"
            :value="
              type === 'hailIn'
                ? $t('editTrip.hailInCallAddButton')
                : $t('editTrip.hailOutCallAddButton')
            "
          />
        </p>
      </fieldset>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";

import Select from "@/components/Select.vue";
import DateFormat from "@/utils/dateFormat.js";
import GenericInput from "@/components/GenericInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";

import { mapping } from "@/utils/FormStateMapping";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import { BOverlay, BIcon, BModal } from "bootstrap-vue";
import Summary from "./EffortStepComponents/Summary.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import HailInDetails from "@/components/subformEditTrip/HailInDetails.vue";
import Popup from "@/components/widgets/Popup.vue";
import RadioInput from "@/components/widgets/RadioInput.vue";

export default {
  name: "HailForm",
  components: {
    GenericInput,
    Select,
    Summary,
    HailInDetails,
    Popup,
    RadioInput,
    DateInput,
    UnitConverter
  },
  props: {
    hails: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return ["hailOut", "hailIn"].includes(value);
      }
    },
    refreshError: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    },
    areas: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      hailRefresh: 0,
      lastDateValue: null,
      refreshIndex: [],
      hasHailInEstimates: false
    };
  },
  computed: {
    ...mapGetters(["errorMessageDateOverlap", "getPropertyValue"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      //  TODO: must be computed here because the list depends on hasHailIn/OutCompany
      hailInCallProviders: state =>
        state.editTripSubform[mapping.HailInCallProviders.stateName],
      hailOutCallProviders: state =>
        state.editTripSubform[mapping.HailOutCallProviders.stateName],
      stateErrors: state => state.editTripSubform.errors,
      systemsLists: state => state.systemsLists,
      openTrip: state => state.currentOpenTrip,
      subform: state => state.editTripSubform.subform,
      currentPage: state => state.editTripSubform.currentPage
    }),
    hasHailOutCompany() {
      return this.getPropertyValue("hasHailOutCompany", {
        subforms: this.subform
      });
    },
    hasHailInCompany() {
      return this.getPropertyValue("hasHailInCompany", {
        subforms: this.subform
      });
    },
    checkCloseDate() {
      if (this.hails && this.hails !== null) {
        let lastIndex = this.hails.length - 1;
        if (
          this.hails[lastIndex].closeDatetime &&
          this.hails[lastIndex].closeDatetime !== null
        ) {
          return true;
        }
      }
      return false;
    },
    hasFishingCompleted() {
      // const extraFields = this.allowedDfoRegion.hailinExtraFields;
      // if (extraFields && extraFields.indexOf("isFishingCompleted") != -1)
      //   return true;
      // return false;
      return false;
    },
    hailInMinDate() {
      if (this.openTrip.departure && this.openTrip.departure["datetime"]) {
        return [
          {
            value: this.openTrip.departure["datetime"],
            text: this.$t("editTrip.beforeDepartureDate")
          }
        ];
      }
      return undefined;
    },
    hailInMaxDate() {
      if (this.openTrip.arrival && this.openTrip.arrival["datetime"]) {
        return [
          {
            value: this.openTrip.arrival["datetime"],
            text: this.$t("editTrip.earlierArrivalDate")
          }
        ];
      }
      return undefined;
    },
    hasHailInDetails() {
      return this.getPropertyValue("hasHailInDetails", {
        subforms: this.subform
      });
    }
  },
  watch: {
    "openTrip.departure": {
      handler: function() {
        this.getLastDateValue();
      }
    },
    areas() {
      this.updateHailInMapping();
    },
    hasHailInEstimates() {
      if (!this.hasHailInEstimates) {
        for (let i = 0; i < this.hails.length; i++) {
          delete this.hails[i]["estimatedArrival"];
          delete this.hails[i]["weightOnBoard"];
        }
      }
    },
    currentPage() {
      if (this.currentPage === "lastStep") {
        if (!this.hails || this.hails.length === 0) {
          if (this.type === "hailIn") {
            this.addHail();
          }
        }
      } else if (this.currentPage === "departureStep") {
        if (!this.hails || this.hails.length === 0) {
          if (this.type === "hailOut") {
            this.addHail();
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(["closeObject", "addErrorsToState", "lastDate"]),
    totalWeightFromTargetCatch() {
      let total = 0;
      this.openTrip.efforts.forEach(effort => {
        effort.tows.forEach(tow => {
          if (tow.catches.length > 0) {
            total += tow.catches[0]["keptWeight"];
          }
        });
      });
      return total;
    },
    async getLastDateValue() {
      const departureDateTime = this.openTrip?.departure?.datetime;
      const result = await this.lastDate({
        departureDateTime: departureDateTime,
        vm: this
      });
      this.lastDateValue = result;
    },
    addErrors(errors, index) {
      this.addErrorsToState({ page: `${this.type}-${index}`, errors: errors });
      this.addErrorToParent(errors);
    },
    async closeHail(index) {
      Vue.set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      const vm = this;
      this.closeObject({
        value: this.hails,
        type: `${this.type}-${index}`,
        index: index,
        vm: vm
      });
    },
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    },
    async addHail() {
      this.hailRefresh += 1;
      const vm = this;
      await vm.$nextTick();
      await vm.$nextTick();

      const errorsFieldsNames = keysMatchingValue(
        this.stateErrors,
        true,
        `${this.type}-${this.hails.length - 1}`
      );
      if (errorsFieldsNames.length > 0) {
        scrollToTopField(errorsFieldsNames);
        return;
      }
      if (this.type === "hailOut") {
        this.hails.push({
          provider: vm.subscription.preferences.hailOutCallCompany
        });
      } else {
        let weight = null;
        if (this.hasHailInEstimates && this.hails.length === 0) {
          weight = this.totalWeightFromTargetCatch();
        }
        this.hails.push({
          provider: vm.subscription.preferences.hailCallCompany,
          weightOnBoard: weight,
          details: []
        });
      }
      this.refreshIndex.push(0);
    },
    removeHail() {
      this.hails.pop();
    },
    updateHailInMapping() {
      for (let i = 0; i < this.areas.length; i++) {
        const area = this.areas[i];
        this.hasHailInEstimates =
          this.hasHailInEstimates ||
          this.getPropertyValue(
            "hasHailInEstimates",
            { subforms: this.subform, areas: area },
            false
          );
      }
    }
  },
  beforeMount() {
    this.refreshIndex = Array(this.hails.length).fill(0);
    this.updateHailInMapping();
  }
};
</script>
