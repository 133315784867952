var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"name":"TowViewer"}},_vm._l((_vm.tows),function(tow,towIndex){return _c('div',{key:'tow-' + towIndex,attrs:{"name":'Tow ' + towIndex}},[_c('fieldset',{staticClass:"fieldsetViewTrip"},[_c('h4',{staticClass:"level2 sticky viewStep"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.tow"))+" "+_vm._s(towIndex + 1)+" ")]),(tow.startPosition || tow.startDate)?_c('div',[_c('h4',{staticClass:"level3"},[_vm._v(" "+_vm._s(_vm.isTowTextMode ? _vm.$t("editTrip.effortStep.startTow") : _vm.$t("editTrip.effortStep.startDeployment"))+" ")]),(tow.startDate)?_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.start'),"value":_vm.$showDate(tow.startDate),"type":"text"}})],1),(tow['startArea'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('fields.area'),"value":tow['startArea'],"options":_vm.areas,"type":"select"}})],1):_vm._e()],1):_vm._e(),(tow.startPosition && tow.startPosition['latitude'])?_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(tow.startPosition['latitude'], 'lat') +
                  ' = ' +
                  tow.startPosition['latitude'] +
                  '°',"type":"text"}})],1),_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(tow.startPosition['longitude'], 'lon') +
                  ' = ' +
                  tow.startPosition['longitude'] +
                  '°',"type":"text"}})],1),(tow.startPosition['nafo'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('nafo'),"value":tow.startPosition['nafo'],"type":"text"}})],1):_vm._e(),(tow.startPosition['grid'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('grid'),"value":tow.startPosition['grid'],"type":"text"}})],1):_vm._e(),(tow.startPosition.lgrid)?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('lgrid'),"value":tow.startPosition.lgrid,"options":_vm.systemsLists.lGrids,"type":"text"}})],1):_vm._e()],1):_vm._e()],1):_vm._e(),(tow['startDate'] && _vm.isOldModule)?_c('div',[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.startDeployment"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.start'),"value":_vm.$showDate(tow['startDate']),"type":"text"}})],1)],1),(tow['startPosition']['latitude'])?_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(tow['startPosition']['latitude'], 'lat') +
                  ' = ' +
                  tow['startPosition']['latitude'] +
                  '°',"type":"text"}})],1),_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(tow['startPosition']['longitude'], 'lon') +
                  ' = ' +
                  tow['startPosition']['longitude'] +
                  '°',"type":"text"}})],1)],1):_vm._e()],1):_vm._e(),_c('div',[_c('InfoViewer',{attrs:{"tow":tow,"area":_vm.area}})],1),(tow.catches && tow.catches.length > 0)?_c('h4',{staticClass:"level3"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.catches"))+" ")]):_vm._e(),_vm._l((tow.catches),function(capture,catchIndex){return _c('CatchViewer',{key:'capture-' + catchIndex,attrs:{"capture":capture}})}),(tow.bycatches && tow.bycatches.length > 0)?_c('h4',{staticClass:"level3"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.bycatches"))+" ")]):_vm._e(),_vm._l((tow.bycatches),function(capture,bycatchIndex){return _c('CatchViewer',{key:'bycatch-' + bycatchIndex,attrs:{"capture":capture}})}),(tow.tags && tow.tags.length > 0)?_c('TagsViewer',{attrs:{"tags":tow.tags,"headerLevel":3}}):_vm._e(),(tow['farGearPosition'])?_c('div',[_c('h4',{staticClass:"level3"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.farGear"))+" ")]),(tow['farGearPosition'])?_c('b-row',[(tow['farGearPosition']['latitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(tow['farGearPosition']['latitude'], 'lat') +
                  ' = ' +
                  tow['farGearPosition']['latitude'] +
                  '°',"type":"text"}})],1):_vm._e(),(tow['farGearPosition']['longitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(tow['farGearPosition']['longitude'], 'lon') +
                  ' = ' +
                  tow['farGearPosition']['longitude'] +
                  '°',"type":"text"}})],1):_vm._e()],1):_vm._e()],1):_vm._e(),(tow['endPosition'] || tow['endDate'])?_c('div',{attrs:{"name":"endTow"}},[_c('h4',{staticClass:"level3"},[_vm._v(" "+_vm._s(_vm.isTowTextMode ? _vm.$t("editTrip.effortStep.endTow") : _vm.$t("editTrip.effortStep.endDeployment"))+" ")]),_c('b-row',[(tow['endDate'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.end'),"value":_vm.$showDate(tow['endDate']),"type":"text"}})],1):_vm._e(),(tow['endArea'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('fields.area'),"value":tow['endArea'],"options":_vm.areas,"type":"select"}})],1):_vm._e()],1),(tow['endPosition'])?_c('b-row',[(tow['endPosition']['latitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(tow['endPosition']['latitude'], 'lat') +
                  ' = ' +
                  tow['endPosition']['latitude'] +
                  '°',"type":"text"}})],1):_vm._e(),(tow['endPosition']['longitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(tow['endPosition']['longitude'], 'lon') +
                  ' = ' +
                  tow['endPosition']['longitude'] +
                  '°',"type":"text"}})],1):_vm._e(),(tow.endPosition['nafo'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('nafo'),"value":tow.endPosition['nafo'],"type":"text"}})],1):_vm._e(),(tow.endPosition['grid'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('grid'),"value":tow.endPosition['grid'],"type":"text"}})],1):_vm._e(),(tow.endPosition.lgrid)?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('lgrid'),"value":tow.endPosition.lgrid,"options":_vm.systemsLists.lGrids,"type":"text"}})],1):_vm._e()],1):_vm._e()],1):_vm._e(),(
          tow['nbVnotchObserved'] ||
            tow['nbSelfVnotched'] ||
            tow['nbBigLobster']
        )?_c('h4',{staticClass:"level3"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.special"))+" ")]):_vm._e(),(
          tow['nbVnotchObserved'] ||
            tow['nbSelfVnotched'] ||
            tow['nbBigLobster']
        )?_c('fieldset',{attrs:{"name":"nbVnotchObserved"}},[_c('b-row',[(tow['nbVnotchObserved'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.nbVnotchObserved'),"value":tow['nbVnotchObserved'],"type":"text"}})],1):_vm._e(),(tow['nbSelfVnotched'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.nbSelfVnotched'),"value":tow['nbSelfVnotched'],"type":"text"}})],1):_vm._e(),(tow['nbBigLobster'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.nbBigLobster'),"value":tow['nbBigLobster'],"type":"text"}})],1):_vm._e()],1)],1):_vm._e(),(
          tow.gearLossEvents && tow.gearLossEvents.length > 0 && !_vm.isOldModule
        )?_c('h4',[_vm._v(" "+_vm._s(_vm.$t("editTrip.gearLossStep.title"))+" ")]):_vm._e(),(!_vm.isOldModule)?_c('div',_vm._l((tow.gearLossEvents),function(gearLoss,gearlossIndex){return _c('fieldset',{key:'gearloss-' + gearlossIndex},[(tow.gearLossEvents.length > 1)?_c('h4',[_vm._v(" "+_vm._s(_vm.$t("editTrip.gearLossStep.loss") + " " + (gearlossIndex + 1))+" ")]):_vm._e(),_c('b-row',[(gearLoss.occursAt['datetime'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.summary.datetime'),"value":_vm.$showDate(gearLoss.occursAt['datetime']),"type":"text"}})],1):_vm._e()],1),_c('b-row',[(gearLoss.occursAt['latitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(gearLoss.occursAt['latitude'], 'lat') +
                    ' = ' +
                    gearLoss.occursAt['latitude'] +
                    '°',"type":"text"}})],1):_vm._e(),(gearLoss.occursAt['longitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(gearLoss.occursAt['longitude'], 'lon') +
                    ' = ' +
                    gearLoss.occursAt['longitude'] +
                    '°',"type":"text"}})],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.gearLossStep.gearType'),"value":gearLoss['gearType'],"options":_vm.systemsLists.gearType,"type":"select"}})],1),(gearLoss['nbGear'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.gearLossStep.nbGear'),"value":gearLoss['nbGear'],"type":"text"}})],1):_vm._e()],1),(gearLoss['remark'])?_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.remarkTitle'),"value":gearLoss['remark'],"type":"text"}})],1)],1):_vm._e()],1)}),0):_vm._e(),(tow.baitUsages && tow.baitUsages.length > 0)?_c('BaitViewer',{attrs:{"baitUsages":tow.baitUsages}}):_vm._e(),_c('br')],2)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }