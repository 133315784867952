<template>
  <div class="page insideApp">
    <h1>
      {{ $t("editTrip.inactivityStep.title") }}
    </h1>
    <h2>
      {{ $t("editForm.subtitle") }}
    </h2>
    <div class="content">
      <b-row>
        <b-col cols="6">
          <div class="topPageMenu">
            <a @click="returnClick" v-html="$t('editForm.returnToRegister')" />
          </div>
        </b-col>
      </b-row>
      <form class="editTripForm">
        <div class="step">
          <h3 class="step-title">
            <span>
              <span>
                <span>{{ $t("editTrip.inactivityStep.title") }}</span>
              </span>
            </span>
          </h3>
          <fieldset>
            <h4 v-if="form.inactivity['reportUID']" class="level1">
              {{ $t("editForm.identification") }}
              <span
                v-b-modal="'modal-inactivity-remark'"
                class="add-remark-btn-title"
                v-show="form.inactivity['closeDatetime'] === undefined"
              ></span>
            </h4>
            <Popup
              v-model="form.inactivity['remark']"
              :title-label="$t('editTrip.remarkTitle')"
              :maxlength="2000"
              :modalName="'modal-inactivity-remark'"
            />

            <b-row v-if="form.inactivity['reportUID']">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :value="form.inactivity['reportUID']"
                  :floatLeft="true"
                  type="text"
                  :label="$t('editTrip.inactivityStep.reportUID')"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :value="form.software['version']"
                  :label="$t('editTrip.subscriptionStep.version')"
                  :floatLeft="true"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :value="form.software['provider']"
                  :label="$t('editTrip.subscriptionStep.provider')"
                  :floatLeft="true"
                  type="text"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :value="$showDate(form['creation'])"
                  :label="$t('editForm.creation')"
                  :floatLeft="true"
                  type="text"
                />
              </b-col>
            </b-row>
            <fieldset v-if="form.inactivity['reportUID']">
              <h4 v-if="form.inactivity['reportUID']" class="level1">
                {{ $t("editTrip.inactivityStep.info") }}
              </h4>
              <Select
                :label="$t('editTrip.subscriptionStep.dfo')"
                :help="$t('editTrip.subscriptionStep.dfoHelp')"
                v-model="form['dfoRegion']"
                :options="systemsLists.dfoRegions"
                :required="true"
                @error="checkError"
                :refeshError="refeshError"
              />
              <br />
              <GenericInput
                v-show="needOperatorName"
                :label="$t('fields.operatorName')"
                v-model="form.operator.name"
                type="datalist"
                :options="suboperators"
                :otherValue="true"
                @binding="
                  function changeValue(value) {
                    form.operator.fisherIdentificationNumber = value[1];
                  }
                "
                :required="needOperatorName"
                :maxlength="80"
                @error="checkError"
                :refeshError="refeshError"
              />
              <GenericInput
                :label="$t('editTrip.subscriptionStep.operatorFin')"
                :help="$t('editTrip.subscriptionStep.operatorFinHelp')"
                v-model="form.operator.fisherIdentificationNumber"
                type="datalist"
                :options="suboperatorsFin"
                :otherValue="true"
                @binding="
                  function changeValue(value) {
                    form.operator.name = value[1];
                  }
                "
                :required="!isArctic"
                :finValidator="true"
                @error="checkError"
                :refeshError="refeshError"
              />
              <br />
              <GenericInput
                :label="$t('fields.vesselName')"
                v-model="form.vessel.name"
                type="datalist"
                :options="vessels"
                :otherValue="true"
                @binding="
                  function changeValue(value) {
                    form.vessel.registeredNumber = value[1];
                  }
                "
                :maxlength="50"
                @error="checkError"
                :refeshError="refeshError"
                :required="needVesselName"
              />
              <GenericInput
                :label="$t('fields.vesselNumber')"
                v-model="form.vessel.registeredNumber"
                type="datalist"
                :options="vesselsRN"
                :otherValue="true"
                @binding="
                  function changeValue(value) {
                    form.vessel.name = value[1];
                  }
                "
                :digits="true"
                :minlength="4"
                :min="1000"
                :maxlength="$const.VRN_MAX_LENGTH"
                @error="checkError"
                :refeshError="refeshError"
                :required="needVesselRegisteredNumber"
              />
            </fieldset>
          </fieldset>
          <InactivityForm
            v-model="form.inactivity"
            type="Inactivity"
            :refresh="refeshError"
            @clear-data="clearData = !clearData"
          />
          <input
            class="stepperBtn reviewBtn"
            @click="clickReview"
            type="button"
            :value="$t('reviewAction')"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";
import LabelView from "@/components/LabelView.vue";
import InactivityForm from "@/components/subformEditTrip/InactivityForm.vue";
import Popup from "@/components/widgets/Popup.vue";
const ARCTIC = 1008;

export default {
  name: "EditForm",
  components: {
    GenericInput,
    LabelView,
    InactivityForm,
    Popup,
    Select
  },
  data: function() {
    return {
      form: {
        inactivity: {},
        software: {},
        vessel: {},
        operator: {}
      },
      error: {},
      id: "",
      status: "open",
      refeshError: 0,
      suboperators: [],
      suboperatorsFin: [],
      vessels: [],
      vesselsRN: [],
      clearData: false
    };
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        if (this.form.inactivity.creation) {
          this.form.creation = this.form.inactivity.creation;
        }
        if (this.form.inactivity.reportUID) {
          this.form.reportUID = this.form.inactivity.reportUID;
        }
        if (!this.needOperatorName) {
          delete this.form.operator.name;
        }
        this.setCurrentOpenTrip({
          uuid: this.id,
          currentOpenTrip: this.form
        });
      }
    },
    generalError() {
      this.error = { ...this.error, ...this.generalError };
    },
    clearData() {
      this.form = {
        inactivity: {},
        software: {},
        vessel: {},
        operator: {}
      };
      this.form.software["version"] = process.env.VUE_APP_VERSION;
      this.form.software["provider"] = this.$t("editTrip.providerName");
    }
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      openForm: state => state.currentOpenTrip,
      userProfile: state => state.userProfile,
      generalError: state => state.editTrip.generalError
    }),
    isArctic() {
      return this.form["dfoRegion"] == ARCTIC;
    },
    needOperatorName() {
      return (
        !this.isArctic ||
        (this.form.operator.fisherIdentificationNumber &&
          this.form.operator.fisherIdentificationNumber.length > 0)
      );
    },
    needVesselName() {
      return this.form.vessel["registeredNumber"] && true;
    },
    needVesselRegisteredNumber() {
      return this.form.vessel["name"] && true;
    }
  },
  methods: {
    ...mapActions(["setCurrentOpenTrip", "resetEditTripState"]),
    returnClick() {
      this.$router.push({
        name: "formList"
      });
    },
    refreshForm() {
      this.error = {};
      this.refeshError++;
    },
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    async clickReview() {
      this.refreshForm();
      await this.$nextTick();
      if (this.$hasInputErrorAndScroll()) {
        this.$hasInputErrorAndScroll();
        return;
      } else {
        this.pushReview();
      }
    },
    async pushReview() {
      const vm = this;
      vm.form.status = "review";
      await vm.setCurrentOpenTrip({
        uuid: this.id,
        currentOpenTrip: vm.form
      });
      await this.$nextTick();
      vm.$router.push({
        name: "reviewForm",
        params: {
          reportId: this.id,
          luid: vm.form["logbookUID"]
        }
      });
    }
  },
  mounted() {
    if (this.userProfile) {
      if (this.userProfile.operators) {
        const values = Object.values(this.userProfile.operators);
        this.suboperators = [];
        this.suboperatorsFin = [];
        for (let i = 0; i < values.length; i++) {
          this.suboperators.push({
            text: values[i].name + " #" + values[i].fisherIdentificationNumber,
            value: values[i].name + "??" + values[i].fisherIdentificationNumber
          });
          this.suboperatorsFin.push({
            text: values[i].name + " #" + values[i].fisherIdentificationNumber,
            value: values[i].fisherIdentificationNumber + "??" + values[i].name
          });
        }
      }

      if (this.userProfile.vessels) {
        const values = Object.values(this.userProfile.vessels);
        this.vessels = [];
        this.vesselsRN = [];
        for (let i = 0; i < values.length; i++) {
          this.vessels.push({
            text: values[i].name + " #" + values[i].registeredNumber,
            value: values[i].name + "??" + values[i].registeredNumber
          });
          this.vesselsRN.push({
            text: values[i].name + " #" + values[i].registeredNumber,
            value: values[i].registeredNumber + "??" + values[i].name
          });
        }
      }
    }
  },
  created() {
    this.id = this.$route.params.reportId;
    this.form.software["version"] = process.env.VUE_APP_VERSION;
    this.form.software["provider"] = this.$t("editTrip.providerName");
    this.form.status = "open";
    this.form = { ...this.form, ...this.openForm };
    this.form = JSON.parse(JSON.stringify(this.form));
    this.resetEditTripState();
  },
  beforeDestroy() {
    this.setCurrentOpenTrip({
      uuid: this.id,
      currentOpenTrip: this.form
    });
  },
  beforeRouteLeave(to, from, next) {
    if (["reviewForm"].includes(to.name)) {
      const luid = to.params.luid;
      this.$store.dispatch("selectTrip", luid).then(next());
    } else {
      next();
    }
  }
};
</script>
