<template>
  <div name="PersonalUseViewer">
    <div v-for="(usage, index) in personalUses" :key="'pcons-' + index">
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="level1 viewStep">
            {{ $t("personalUse.itemTitle") }} {{ index + 1 }}
          </h4>
          <b-row>
            <b-col v-if="usage.usage" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('personalUse.usage')"
                :value="usage.usage"
                :options="systemsLists.catchUsages"
                type="select"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.lastStep.specie')"
                :value="usage.species"
                :options="systemsLists.species"
                type="select"
              />
            </b-col>
            <b-col v-if="usage.productForm" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.lastStep.productForm')"
                :value="usage.productForm"
                :options="systemsLists.productForm"
                type="select"
              />
            </b-col>
            <b-col v-if="usage.size" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.lastStep.size')"
                :value="usage.size"
                :options="systemsLists.productSize"
                type="select"
              />
            </b-col>
            <b-col v-if="usage.weight" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.lastStep.weight')"
                :value="usage.weight"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="usage.kept" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('catch.keptNbSpecimen')"
                :value="usage.kept"
                type="text"
              />
            </b-col>
            <b-col v-if="usage.remark" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="usage.remark"
                type="text"
              />
            </b-col>
            <b-col v-if="usage['closeDatetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('dataGroupClosureDate')"
                :value="$showDate(usage['closeDatetime'])"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "PersonalUseViewer",
  components: {
    LabelView,
    UnitConverter
  },
  props: {
    personalUses: Array
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      userProfile: state => state.userProfile
    })
  },
  methods: {}
};
</script>
