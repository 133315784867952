<template>
  <fieldset v-if="showVesselSection">
    <h4 class="level1">{{ $t("section.vessel") }}</h4>
    <RadioInput
      v-if="hasGeneralInfoVrn === $const.OPTIONAL"
      v-model="useVessel"
      :label="$t('fields.useVessel')"
      :options="responses"
      :refeshError="refreshError"
      required
      @error="checkError"
      @input="onHasVessel"
    />
    <br v-if="hasGeneralInfoVrn === $const.OPTIONAL" />
    <GenericInput
      v-if="hasGeneralInfoVname"
      v-model="form.vessel.name"
      :label="$t('fields.vesselName')"
      :required="hasGeneralInfoVname === $const.MANDATORY"
      :maxlength="50"
      :refeshError="refreshError"
      type="text"
      @error="checkError"
    />
    <GenericInput
      v-if="hasGeneralInfoVrn"
      v-model="form.vessel.registeredNumber"
      :label="$t('fields.vesselNumber')"
      :required="hasGeneralInfoVrn === $const.MANDATORY"
      :minlength="4"
      :min="1000"
      :maxlength="$const.VRN_MAX_LENGTH"
      :refeshError="refreshError"
      type="text"
      digits
      @error="checkError"
    />
    <!-- TODO: As far as I can tell, hasPartnership is not related to Vessel. -->
    <RadioInput
      v-if="hasPartnership"
      v-model="form.partnership"
      :label="$t('fields.partnership')"
      :options="$t('mv_table.partnership')"
      :refeshError="refreshError"
      :required="hasPartnership === $const.MANDATORY"
      @error="checkError"
    />
    <!-- TODO: isCarrier and hasCarrier could be in their own component.
               Observer fields should be in a component such as Observer.vue
               hasTripFishingIndicator goes elsewhere. Maybe in the last step.
     -->
    <fieldset v-if="isEditTrip">
      <RadioInput
        v-if="hasCarrier"
        v-model="form['useCarrier']"
        :label="$t('carrier.useIndicator')"
        :options="responses"
        :refeshError="refreshError"
        :required="hasCarrier === $const.MANDATORY"
        @error="checkError"
      />
      <RadioInput
        v-if="hasOptionObserverOnBoard"
        v-model="form['observerOnBoard']"
        :label="$t('editTrip.subscriptionStep.observerOnBoard')"
        :options="responses"
        :refeshError="refreshError"
        :required="hasOptionObserverOnBoard === $const.MANDATORY"
        @error="checkError"
      />
      <GenericInput
        v-if="hasObserverID"
        v-model="form['observerId']"
        :label="$t('editTrip.subscriptionStep.observerId')"
        :required="hasObserverID === $const.MANDATORY"
        :maxlength="20"
        :refeshError="refreshError"
        type="text"
        @error="checkError"
      />
      <GenericInput
        v-if="hasObserverName"
        v-model="form['observerName']"
        :label="$t('editTrip.subscriptionStep.observerName')"
        :required="hasObserverName === $const.MANDATORY"
        :maxlength="50"
        :refeshError="refreshError"
        type="text"
        @error="checkError"
      />
      <GenericInput
        v-if="hasObserverNumber"
        v-model="form.observerTripNumber"
        :required="hasObserverNumber === $const.MANDATORY"
        :label="$t('editTrip.subscriptionStep.observer')"
        :maxlength="12"
        :refeshError="refreshError"
        type="text"
        @error="checkError"
      />
      <RadioInput
        v-if="hasTripFishingIndicator"
        v-model="form.hasFished"
        :label="$t('editTrip.subscriptionStep.hasFished')"
        :options="responses"
        :refeshError="refreshError"
        :required="hasTripFishingIndicator === $const.MANDATORY"
        @error="checkError"
      />
      <RadioInput
        v-if="isCarrier"
        v-model="form.isCarrier"
        :label="$t('carrier.isIndicator')"
        :options="responses"
        :refeshError="refreshError"
        :required="isCarrier === $const.MANDATORY"
        @error="checkError"
      />
    </fieldset>
  </fieldset>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import { mapGetters, mapState } from "vuex";
import RadioInput from "@/components/widgets/RadioInput.vue";

export default {
  name: "Vessel",
  components: {
    GenericInput,
    RadioInput
  },
  props: {
    checkError: Function,
    refreshError: Number,
    form: Object,
    isEditTrip: {
      default: false,
      type: Boolean
    }
  },
  data: function() {
    return {
      useVessel: this.$const.YES
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subform: state => state.editTripSubform.subform,
      responses: state => state.systemsLists.response
    }),
    hasGeneralInfoVname() {
      return this.getPropertyValue("hasGeneralInfoVname", {
        subforms: this.subform
      });
    },
    hasGeneralInfoVrn() {
      return this.getPropertyValue("hasGeneralInfoVrn", {
        subforms: this.subform
      });
    },
    hasPartnership() {
      return this.getPropertyValue("hasPartnership", {
        subforms: this.subform
      });
    },
    hasCarrier() {
      return this.getPropertyValue("hasCarrier", { subforms: this.subform });
    },
    hasObserverID() {
      return this.getPropertyValue("hasObserverID", { subforms: this.subform });
    },
    hasObserverName() {
      return this.getPropertyValue("hasObserverName", {
        subforms: this.subform
      });
    },
    hasObserverNumber() {
      return this.getPropertyValue("hasObserverNumber", {
        subforms: this.subform
      });
    },
    hasOptionObserverOnBoard() {
      return this.getPropertyValue("hasOptionObserverOnBoard", {
        subforms: this.subform
      });
    },
    isCarrier() {
      return this.getPropertyValue("isCarrier", { subforms: this.subform });
    },
    hasTripFishingIndicator() {
      return this.getPropertyValue("hasTripFishingIndicator", {
        subforms: this.subform
      });
    },
    showVesselSection() {
      return (
        this.isEditTrip ||
        this.hasPartnership !== this.$const.BLOCKED ||
        this.hasGeneralInfoVrn !== this.$const.BLOCKED ||
        this.hasGeneralInfoVname !== this.$const.BLOCKED
      );
    }
  },
  watch: {
    hasPartnership: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.form.partnership;
        }
      }
    },
    hasGeneralInfoVname: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          this.form.vessel.name = null;
        }
      }
    },
    hasGeneralInfoVrn: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          this.form.vessel.registeredNumber = null;
        }
      }
    }
  },
  methods: {
    onHasVessel(value) {
      if (value === this.$const.NO) {
        this.form.vessel.name = null;
        this.form.vessel.registeredNumber = null;
      }
    }
  },
  mounted() {
    this.useVessel =
      this.form.vessel.registeredNumber !== null ||
      this.form.vessel.name !== null
        ? this.$const.YES
        : this.$const.NO;
  }
};
</script>
