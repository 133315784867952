<template>
  <div id="pagePasswordChange" class="page insideApp">
    <h1>{{ $t("password.change") }}</h1>
    <div class="content">
      <div class="topPageMenu">
        <router-link to="/userprofile">
          {{ $t("password.button.back") }}
        </router-link>
      </div>
      <form
        class="password_change"
        @submit.prevent="editPassword"
        autocomplete="off"
      >
        <fieldset>
          <GenericInput
            v-show="false"
            v-model.trim="form.username"
            :label="$t('fin.label')"
            type="text"
            autocomplete="username"
          />
          <PasswordInput
            v-model="form.currentPassword"
            :label="$t('password.current')"
            :placeholder="$t('password.current')"
            :required="true"
            :displayPassword="true"
            :forceErrorMsg="passwordRemoteError"
            :refeshError="refeshError"
            autocomplete="current-password"
            @error="checkError"
          />
          <br />
          <PasswordInput
            v-model="form.newPassword"
            :label="$t('password.new')"
            :placeholder="$t('password.new')"
            :required="true"
            :displayPassword="true"
            :minlength="8"
            :refeshError="refeshError"
            autocomplete="new-password"
            @error="checkError"
            @binding="checkPasswordConfirmation"
          />
          <br />
          <PasswordInput
            v-model="form.newPasswordAgain"
            :label="$t('password.confirm')"
            :placeholder="$t('password.confirm')"
            :required="true"
            :displayPassword="true"
            :forceErrorMsg="passwordConfirmError"
            :refeshError="refeshError"
            autocomplete="new-password"
            @error="checkError"
            @binding="checkPasswordConfirmation"
          />
        </fieldset>
        <button
          class="saveButton"
          :class="!isConnectedOnline && 'deactivated'"
          type="submit"
        >
          {{ $t("password.button.save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import PasswordInput from "@/components/widgets/PasswordInput.vue";

export default {
  name: "passwordchange",
  components: {
    GenericInput,
    PasswordInput
  },
  data: () => ({
    form: {
      username: "",
      currentPassword: "",
      newPassword: "",
      newPasswordAgain: ""
    },
    passwordRemoteError: false,
    passwordConfirmError: false,
    error: {},
    refeshError: false
  }),
  computed: {
    isConnectedOnline() {
      return this.$store.getters.IS_APP_CONNECTED_ONLINE;
    }
  },
  methods: {
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    },
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    refreshForm() {
      this.refeshError = !this.refeshError;
    },
    checkPasswordConfirmation(e) {
      const hasPassword =
        this.form.newPassword && this.form.newPassword.length > 0;
      const hasConfirmation =
        this.form.newPasswordAgain && this.form.newPasswordAgain.length > 0;
      if (
        hasPassword &&
        hasConfirmation &&
        this.form.newPassword != this.form.newPasswordAgain
      ) {
        this.passwordConfirmError = this.$i18n.t("validator.unconfirmed");
        this.refeshError = !this.refeshError;
      }
    },
    editPassword(e) {
      // if (!this.isConnectedOnline) {
      //   this.onlinePopupError();
      //   return;
      // }
      const vuejs = this;

      const handleSuccess = function(response) {
        vuejs.$store
          .dispatch("updateSpecialK", vuejs.form.newPassword)
          .then(() =>
            vuejs.$store.dispatch(
              "alert/success",
              vuejs.$i18n.t("message.save_success")
            )
          )
          .then(() => vuejs.$router.push({ name: "userprofile" }));
      };

      vuejs.refreshForm();
      vuejs.$nextTick(() => {
        if (vuejs.$hasInputErrorAndScroll()) {
          return;
        }
        vuejs.$store
          .dispatch("showLoadingOverlay")
          .then(() =>
            vuejs.$store.dispatch("api/editPassword", {
              oldPassword: vuejs.form.currentPassword,
              newPassword: vuejs.form.newPassword
            })
          )
          .then(handleSuccess)
          .catch(error => {
            if (
              error.errorType == "user" &&
              error.errors?.hasOwnProperty("old_password")
            ) {
              this.passwordRemoteError = error.errors["old_password"][0];
            }
            vuejs.$alertUserApiError(error);
          })
          .finally(function() {
            vuejs.$store.dispatch("hideLoadingOverlay");
          });
      });
    }
  },
  watch: {
    "form.currentPassword"(newVal) {
      this.passwordRemoteError = false;
    },
    "form.newPasswordAgain"(newVal) {
      this.passwordConfirmError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.password_change {
  padding: 12px !important;
  background-color: white !important;
  position: relative !important;
}
</style>
