<template>
  <fieldset class="removeBottomPadding">
    <h4 class="level2">
      {{ $t("baitFished.label") }}&nbsp;{{ index + 1 }}
      <span
        v-b-modal="'suppPersonalUseEntry-' + index + '-baitFished-remark'"
        v-show="bait.closeDatetime === undefined"
        class="add-remark-btn-title"
      ></span>
      <Popup
        v-model="bait.remark"
        :title-label="$t('editTrip.remarkTitle')"
        :maxlength="2000"
        :modalName="'suppPersonalUseEntry-' + index + '-baitFished-remark'"
      />
    </h4>
    <b-overlay
      :show="bait.closeDatetime !== undefined"
      rounded="sm"
      blur="none"
      :key="'baitFished' + index + bait.closeDatetime"
    >
      <template #overlay>
        <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
        <p class="redMessage">{{ $t("closeMsg") }}</p>
        <p class="redMessage">
          {{ $t("closeMsgDate") }}{{ $showDate(bait.closeDatetime) }}
        </p>
      </template>
      <fieldset :disabled="bait.closeDatetime !== undefined">
        <Select
          :label="$t('editTrip.effortStep.specie')"
          v-model="bait.species"
          :options="species"
          :required="true"
          @error="e => addLocalError(e)"
          :refeshError="showLocalErrors"
        />
        <UnitConverter
          :label="$t('baitFished.weight')"
          v-model="bait.weight"
          :required="true"
          :min="0"
          :max="999999.999"
          unit="weight"
          @error="e => addLocalError(e)"
          :refresh="showLocalErrors"
        />
      </fieldset>
      <div class="suppEntryButtonWrapper">
        <input
          v-if="!bait.closeDatetime"
          @click="closeBait()"
          class="closeSuppEntryButton"
          type="button"
          :class="
            index + 1 < baitLen ? 'addBottomMargin' : 'removeBottomMargin'
          "
          :value="$t('baitFished.close') + String(index + 1)"
        />
      </div>
    </b-overlay>
  </fieldset>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Select from "@/components/Select.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  name: "BaitFished",
  components: {
    Select,
    Popup,
    UnitConverter
  },
  props: {
    showErrorData: Number,
    addError: Function,
    index: Number,
    bait: Object,
    baitLen: Number,
    species: Array
  },
  data: () => ({
    showLocalErrors: 0,
    form: {}
  }),
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      userProfile: state => state.userProfile, // for showdate
      subform: state => state.editTripSubform.subform,
      showError: state => state.editTripSubform.showError,
      stateErrors: state => state.editTripSubform.errors
    })
  },
  watch: {
    showErrorData() {
      this.showLocalErrors++;
    },
    form: {
      handler: function() {
        this.$emit("update", this.form);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    closeBait() {
      const vm = this;
      vm.showLocalErrors++;
      vm.closeObject({
        value: this.bait,
        type: `baitStep-${this.index}`,
        index: undefined,
        vm: vm
      });
    },
    addLocalError(error) {
      this.addErrorsToState({
        page: `baitStep-${this.index}`,
        errors: error
      });
      this.addError(error);
    }
  },
  beforeMount() {
    Object.assign(this.form, this.bait);
  },
  mounted() {}
};
</script>
