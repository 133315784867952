var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasBaitUsed)?_c('div',[_vm._l((_vm.baitUsages),function(bait,index){return _c('fieldset',{key:'baitEntry' + index,staticClass:"removeBottomPadding"},[_c('h4',{staticClass:"level1"},[_vm._v(" "+_vm._s(_vm.$t("editTrip.baitStep.bait"))+" "+_vm._s(index + 1)+" "),_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('suppBaitEntry-' + index + '-baitUsage-remark'),expression:"'suppBaitEntry-' + index + '-baitUsage-remark'"},{name:"show",rawName:"v-show",value:(bait['closeDatetime'] === undefined),expression:"bait['closeDatetime'] === undefined"}],staticClass:"add-remark-btn-title"}),_c('Popup',{attrs:{"title-label":_vm.$t('editTrip.remarkTitle'),"maxlength":2000,"modalName":'suppBaitEntry-' + index + '-baitUsage-remark'},model:{value:(bait['remark']),callback:function ($$v) {_vm.$set(bait, 'remark', $$v)},expression:"bait['remark']"}})],1),_c('b-overlay',{attrs:{"show":bait['closeDatetime'] !== undefined,"rounded":"sm","blur":"none"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"overlayTextPosition",attrs:{"cols":"1"}},[_c('b-icon',{attrs:{"icon":"file-lock","variant":"danger","scale":"2"}})],1),_c('b-col',[_c('p',{staticClass:"redMessage"},[_vm._v(_vm._s(_vm.$t("closeMsg")))]),_c('br'),_c('p',{staticClass:"redMessage"},[_vm._v(" "+_vm._s(_vm.$t("closeMsgDate"))+_vm._s(_vm.showDate(bait["closeDatetime"]))+" ")])])],1)]},proxy:true}],null,true)},[_c('fieldset',{attrs:{"disabled":bait['closeDatetime'] !== undefined}},[(_vm.hasBaitDatetime)?_c('DateInput',{attrs:{"label":_vm.$t('editTrip.baitStep.datetime'),"minDates":[
            {
              value: _vm.openTrip.departure['datetime'],
              text: _vm.$t('editTrip.beforeDepartureDate')
            }
          ],"passDate":true,"required":true,"hideTime":true,"autodatetime":true,"refeshError":_vm.showErrorData + _vm.refreshIndex[index],"forceErrorMsg":_vm.errorMessageDateOverlap(this, bait['datetime'])},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(bait['datetime']),callback:function ($$v) {_vm.$set(bait, 'datetime', $$v)},expression:"bait['datetime']"}}):_vm._e(),_c('Select',{attrs:{"label":_vm.$t('editTrip.baitStep.type'),"options":_vm.baitOptions,"required":_vm.hasBaitUsed === _vm.$const.MANDATORY,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(bait['type']),callback:function ($$v) {_vm.$set(bait, 'type', $$v)},expression:"bait['type']"}}),(_vm.hasAnyBaitWeight)?_c('UnitConverter',{attrs:{"label":_vm.$t('editTrip.baitStep.weight'),"required":_vm.hasAnyBaitWeight === _vm.$const.MANDATORY,"min":1,"max":999999,"unit":"weight","refresh":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(bait['weight']),callback:function ($$v) {_vm.$set(bait, 'weight', $$v)},expression:"bait['weight']"}}):_vm._e(),(_vm.hasBaitAverageWeight)?_c('UnitConverter',{attrs:{"label":_vm.$t('editTrip.baitStep.averageWeightPerGear'),"required":_vm.hasBaitAverageWeight === _vm.$const.MANDATORY,"min":0,"max":999999.999,"unit":"weight","refresh":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(bait['averageWeightPerGear']),callback:function ($$v) {_vm.$set(bait, 'averageWeightPerGear', $$v)},expression:"bait['averageWeightPerGear']"}}):_vm._e(),(_vm.conditionRequired(bait))?_c('Select',{attrs:{"label":_vm.$t('editTrip.baitStep.condition'),"options":_vm.baitConditionOptions,"required":_vm.conditionRequired(bait) === _vm.$const.MANDATORY,"refeshError":_vm.showErrorData + _vm.refreshIndex[index]},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(bait['condition']),callback:function ($$v) {_vm.$set(bait, 'condition', $$v)},expression:"bait['condition']"}}):_vm._e(),(_vm.hasBaitOrigin)?_c('RadioInput',{attrs:{"label":_vm.$t('editTrip.baitStep.origin'),"options":_vm.$t('baitsOrigin.items'),"refeshError":_vm.showErrorData + _vm.refreshIndex[index],"required":_vm.hasBaitOrigin === _vm.$const.MANDATORY},on:{"error":error => {
              _vm.addError(error, index);
            }},model:{value:(bait['origin']),callback:function ($$v) {_vm.$set(bait, 'origin', $$v)},expression:"bait['origin']"}}):_vm._e()],1)]),(!bait['closeDatetime'])?_c('input',{staticClass:"closeSuppEntryButton",class:index + 1 < _vm.baitUsages.length
          ? 'addBottomMargin'
          : 'removeBottomMargin',attrs:{"type":"button","value":_vm.$t('editTrip.baitStep.closeBait') + String(index + 1)},on:{"click":function($event){return _vm.closeBait(index)}}}):_vm._e()],1)}),_c('div',{staticClass:"suppEntryButtonWrapper",staticStyle:{"display":"block"}},[_c('div',[(_vm.baitUsages.length > 0 && !_vm.$isLastItemClosed(this.baitUsages))?_c('input',{staticClass:"removeSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.baitStep.removeBait') + String(_vm.baitUsages.length)},on:{"click":_vm.removeBait}}):_vm._e(),_c('input',{staticClass:"addSuppEntryButton",attrs:{"type":"button","value":_vm.$t('editTrip.baitStep.addBait')},on:{"click":_vm.addBait}})])])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }