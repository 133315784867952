<template>
  <button
    :class="!isOnline && 'deactivated'"
    class="saveButton"
    @click="onClick"
  >
    {{ $t("save") }}
  </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OnlineButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: "OnlineButton #" + (Math.random() * 100000).toFixed(0)
    };
  },
  computed: {
    ...mapGetters("api", ["isOnline", "getOnlineError"])
  },
  watch: {},
  methods: {
    onClick(e) {
      e.preventDefault();
      if (this.isOnline) {
        this.$emit("proceed", e);
      } else {
        const i18nKey = this.getOnlineError;
        this.$store.dispatch("alert/error", this.$i18n.t(i18nKey));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
button {
  /* from .page.insideApp form button[type=submit] */
  position: absolute;
  bottom: -65px;
  right: 0px;
  width: 350px;
  max-width: 40%;

  /* from .page .stepperBtn, .page form button[type=submit] */
  /* width: 516px; */
  text-transform: uppercase;
  border: none;
  height: 53px;
  background-color: $btn-bleu;
  font-size: 1em;
  color: white;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 0 10px;

  &:hover {
    background-color: $btn-bleu-hover;
  }
  &:active {
    background-color: $btn-active;
  }
  &.deactivated {
    background-color: gray;
    text-decoration: none;
    &:hover,
    &:active {
      background-color: lightgray;
      text-decoration: none;
    }
  }
}
</style>
