<template>
  <p :name="id" class="field-wrapper">
    <span v-if="label" class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
    </span>

    <!--default-->
    <b-input
      v-if="!needNumberValidation"
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="decimalValue"
      type="number"
      @input="onChange($event)"
      :min="min"
      :max="max"
      :inputmode="'decimal'"
      :class="errorClass"
      @blur="focusChanged"
    />
    <b-input
      v-else
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="decimalValue"
      type="text"
      @input="onChange($event)"
      :formatter="formatValue"
      @keydown="onKeydown($event)"
      :min="min"
      :max="max"
      :inputmode="'decimal'"
      :class="errorClass"
      @blur="focusChanged"
    />

    <!--Error-->
    <label v-if="isError || forceErrorMsg" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";

export default {
  name: "DecimalInput",
  props: {
    value: {
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    refeshError: {
      default: false
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    maxDecimal: {
      default: undefined
    }
  },
  data() {
    return {
      id: this.$options.name + "-" + (Math.random() * 100000).toFixed(0),
      decimalValue: null,
      defaultErrorMsg: "",
      errorClass: { error: false },
      decSep: null,
      conversionValues: {
        weightIndex: 2.20462,
        heightIndex: 3.28084,
        distanceIndex: 0.5468
      },
      conversionAttributes: {
        weightAttribute: "KG",
        heightAttribute: "M",
        distanceAttribute: "M"
      },
      unitConversionValues: {
        weightUnits: {
          unit1: "kg",
          unit2: "lb"
        },
        heightUnits: {
          unit1: "m",
          unit2: "ft"
        },
        distanceUnits: {
          unit1: "m",
          unit2: "fm"
        }
      },
      isError: false,
      unit1: "",
      unit2: ""
    };
  },
  computed: {
    filter() {
      return {
        type: "number",
        required: this.required,
        min: this.min,
        max: this.max,
        maxDecimal: this.maxDecimal
      };
    },
    needNumberValidation() {
      if (this.$store) {
        return this.$store.getters.IS_NEED_BROWSER_NUMBER_VALIDATION;
      } else {
        return (
          navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ||
          (navigator.userAgent.toLowerCase().indexOf("safari") > -1 &&
            navigator.userAgent.toLowerCase().indexOf("chrome") == -1)
        );
      }
    },
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      }
      return msg;
    }
  },
  watch: {
    refeshError() {
      this.setError();
    },
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    value: {
      immediate: true,
      handler: function(newValue) {
        this.decimalValue = newValue;
      }
    }
  },
  methods: {
    setError() {
      const rt = Validator.genericValidation(this.decimalValue, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;

      if (rt.notBlocked) {
        return;
      }

      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    focusChanged(e) {
      this.setError();
      this.$emit("binding", this.decimalValue);
    },
    formatValue(value) {
      let x = value;
      x = x.trim();
      if (x.indexOf("-") != 0) {
        x = x.replace(/[^0-9\.\,]/g, "");
      } else {
        x = x.replace(/[^0-9\-\.\,]/g, "");
      }
      if (this.decSep === ".") {
        x = x.replace(",", ".");
      } else {
        x = x.replace(".", ",");
      }
      return x;
    },
    onKeydown(e) {
      if (e.key == "-") {
        let x = "" + this.decimalValue;
        if (x.indexOf("-") != -1) e.preventDefault();
      }
      if (e.key == "." || e.key == ",") {
        let x = "" + this.decimalValue;
        if (x.indexOf(".") != -1 || x.indexOf(",") != -1) e.preventDefault();
      }
    },
    onChange(userInput) {
      let value = userInput;
      if (value === undefined || value === "") {
        value = null;
        this.decimalValue = value;
      } else {
        if (this.needNumberValidation) this.decimalValue = value;
        else this.decimalValue = parseFloat(value);
      }
      this.$emit("input", this.decimalValue);
    },
    getDecimalSeparator() {
      this.decSep = ".";
      let val = 2 / 3;
      const sep = val.toLocaleString().substring(1, 2);
      if (sep === "." || sep === ",") {
        this.decSep = sep;
      }
      return this.decSep;
    }
  },
  mounted() {
    if (this.needNumberValidation) this.getDecimalSeparator();
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>
