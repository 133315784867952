var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.tags && _vm.tags.length > 0)?_c('div',{attrs:{"name":"tagsViewer"}},_vm._l((_vm.tags),function(tag,index){return _c('div',{key:'tag-' + index,staticClass:"towEntriesContainer"},[_c('div',[_c('fieldset',{staticClass:"fieldsetViewTrip"},[_c('h4',{class:'level' + _vm.headerLevel + ' viewStep'},[_vm._v(" "+_vm._s(_vm.$t("editTrip.effortStep.tag"))+" "+_vm._s(index + 1)+" ")]),_c('b-row',[(tag['species'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.specie'),"value":tag['species'],"options":_vm.systemsLists.species,"type":"select"}})],1):_vm._e(),(tag['tag1'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.tag1'),"value":tag['tag1'],"type":"text"}})],1):_vm._e(),(tag['tag2'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.effortStep.tag2'),"value":tag['tag2'],"type":"text"}})],1):_vm._e(),(tag['depth'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('UnitConverter',{attrs:{"label":_vm.$t('editTrip.effortStep.depth'),"value":tag['depth'],"unit":"depth","viewOnly":""}})],1):_vm._e(),(tag['length'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('UnitConverter',{attrs:{"label":_vm.$t('editTrip.effortStep.effortSampleSpecimenLength'),"value":tag['length'],"unit":"cm","viewOnly":""}})],1):_vm._e()],1),_c('b-row',[(tag.position && tag.position['latitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('latitude'),"value":_vm.convertDD2toDMS(tag.position['latitude'], 'lat') +
                  ' = ' +
                  tag.position['latitude'] +
                  '°',"type":"text"}})],1):_vm._e(),(tag.position && tag.position['longitude'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('longitude'),"value":_vm.convertDD2toDMS(tag.position['longitude'], 'lon') +
                  ' = ' +
                  tag.position['longitude'] +
                  '°',"type":"text"}})],1):_vm._e(),(tag['remark'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('editTrip.remarkTitle'),"value":tag['remark'],"type":"text"}})],1):_vm._e(),(tag['closeDatetime'])?_c('b-col',{attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('LabelView',{attrs:{"label":_vm.$t('dataGroupClosureDate'),"value":_vm.$showDate(tag['closeDatetime']),"type":"text"}})],1):_vm._e()],1)],1)])])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }