<template>
  <fieldset
    v-if="mminteration['hasMmi'] === $const.YES"
    class="suppEntryButtonWrapper"
  >
    <h4>{{ $t("editTrip.speciesAtRiskStep.type") }}</h4>
    <fieldset
      v-for="(mmiIncident, incidentIndex) in mminteration.incidentTypes"
      :key="incidentIndex"
    >
      <Select
        :label="
          $t('editTrip.speciesAtRiskStep.type') + ' ' + (incidentIndex + 1)
        "
        :options="mmiTypes"
        v-model="mmiIncident['type']"
        :forceErrorMsg="uniqIncidentType(mmiIncident['type'], incidentIndex)"
        :required="true"
        @error="addErrorToParent"
        :refeshError="showErrorData"
        :key="'incidenttype-' + incidentIndex"
      />
      <GenericInput
        :label="$t('editTrip.remarkTitle')"
        v-model="mmiIncident['desc']"
        type="text"
        :maxlength="150"
        @error="addErrorToParent"
        :refeshError="showErrorData"
        :key="'incidentdesc-' + incidentIndex"
      />
    </fieldset>
    <p>
      <input
        v-if="checkIncident"
        @click="removeIncidentType()"
        class="removeSuppEntryButton"
        type="button"
        :value="$t('editTrip.speciesAtRiskStep.removeIncidentType')"
      />
      <input
        v-if="checkCanAddIncident"
        @click="addIncidentType()"
        class="addSuppEntryButton"
        type="button"
        :value="$t('editTrip.speciesAtRiskStep.addIncidentType')"
      />
    </p>
  </fieldset>
</template>

<script>
import { mapState, mapActions } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";

const emptyIncidentType = function(vm) {
  let incidentTypes = {
    remark: null,
    type: null
  };
  return incidentTypes;
};

export default {
  components: {
    GenericInput,
    Select
  },
  props: {
    addErrorToParent: Function,
    parentShowError: Number,
    mminteration: Object,
    index: Number
  },
  data() {
    return {
      showErrorData: 0,
      name: "incident",
      mmiTypes: []
    };
  },
  watch: {
    parentShowError() {
      this.showErrorData++;
    }
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      openTrip: state => state.currentOpenTrip
    }),
    checkCanAddIncident() {
      if (
        this.mminteration.incidentTypes &&
        this.mminteration.incidentTypes.length > 0
      )
        return (
          this.mminteration.incidentTypes[
            this.mminteration.incidentTypes.length - 1
          ].type != null || this.mminteration.incidentTypes.length == 0
        );
      else return true;
    },
    checkIncident() {
      return (
        this.mminteration.incidentTypes &&
        this.mminteration.incidentTypes !== null &&
        this.mminteration.incidentTypes.length > 1
      );
    }
  },
  beforeMount() {
    const typesWithDefault = this.systemsLists.mmiTypes;
    for (let index = 1; index < typesWithDefault.length; index++) {
      const type = typesWithDefault[index];
      this.mmiTypes.push(type);
    }
  },
  methods: {
    getIncidentTypesArray() {
      let newArray = [];
      for (let elemt in this.mminteration.incidentTypes) {
        if (this.mminteration.incidentTypes[elemt].type != null)
          newArray.push(this.mminteration.incidentTypes[elemt].type);
      }
      return newArray;
    },
    uniqIncidentType(incidentType, index) {
      let currentArray = this.getIncidentTypesArray();
      let occurrences = currentArray.filter(type => type === incidentType);
      const firstIndex = currentArray.indexOf(incidentType);
      if (occurrences.length > 1 && index !== firstIndex) {
        return this.$t("fields.mmiTypeError");
      }
      return undefined;
    },
    emptyIncidentType() {
      let incidentTypes = {
        remark: null,
        type: null
      };
      return incidentTypes;
    },
    removeIncidentType() {
      this.mminteration.incidentTypes = this.mminteration.incidentTypes.slice(
        0,
        -1
      );
    },
    addIncidentType() {
      const vm = this;
      this.showErrorData++;
      vm.$nextTick(() => {
        if (vm.mminteration.incidentTypes === null) {
          vm.mminteration.incidentTypes = [];
        }
        vm.mminteration.incidentTypes.push(emptyIncidentType(this));
      });
    }
  }
};
</script>
