<template>
  <fieldset>
    <h4 class="level2">
      {{ $t("editTrip.effortStep.fishingGears") }}
      <span
        v-b-modal="'modal-Trip-' + index + '-gears'"
        v-show="effort['closeDatetime'] === undefined"
        class="add-remark-btn-title"
      ></span>
      <Popup
        v-model="effort['fishingGearsRemark']"
        :title-label="$t('editTrip.remarkTitle')"
        :maxlength="2000"
        :modalName="'modal-Trip-' + index + '-gears'"
      />
    </h4>
    <Select
      :label="$t('editTrip.effortStep.gears')"
      :options="gearTypeOptions"
      v-model="effort['gears']"
      :required="true"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <Select
      v-if="hasEffortGearSubtype"
      :label="$t('editTrip.effortStep.subType')"
      :options="gearSubtypeOptions"
      v-model="effort['subType']"
      :required="hasEffortGearSubtype === $const.MANDATORY"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="hasEffortGearLength"
      :label="$t('editTrip.effortStep.length')"
      v-model="effort['length']"
      :required="hasEffortGearLength === $const.MANDATORY"
      :min="0"
      :max="999.99"
      unit="m"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <Select
      v-if="hasTrapSize"
      :label="$t('editTrip.effortStep.trapSize')"
      v-model="effort['trapSize']"
      :options="trapSizeOptions"
      :required="hasTrapSize === $const.MANDATORY"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="hasEffortMeshSize"
      :label="$t('fields.meshSize')"
      v-model="effort['meshSize']"
      :required="hasEffortMeshSize === $const.MANDATORY"
      :min="1"
      :max="999"
      unit="mm"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />

    <Select
      v-if="hasMeshType"
      :label="$t('fields.meshType')"
      v-model="effort['meshType']"
      :options="systemsLists.meshTypes"
      :required="hasMeshType === $const.MANDATORY"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <DecimalInput
      v-if="hasMonofilamentGauge"
      :label="$t('editTrip.effortStep.monofilamentGauge')"
      v-model="effort['monofilamentGauge']"
      :required="hasMonofilamentGauge === $const.MANDATORY"
      :min="0"
      :max="999.9"
      :maxDecimal="1"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />

    <UnitConverter
      v-if="hasGangeSpacing"
      :label="$t('editTrip.effortStep.gangesSpacing')"
      v-model="effort['gangesSpacing']"
      :min="1"
      :max="999"
      unit="cm"
      :required="hasGangeSpacing === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />

    <UnitConverter
      v-if="hasGangionLength"
      :label="$t('editTrip.effortStep.gangionLength')"
      v-model="effort['gangionLength']"
      :min="1"
      :max="999"
      unit="cm"
      :required="hasGangionLength === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />

    <Select
      v-if="hasEffortByGearHookSizeId"
      :label="$t('editTrip.effortStep.hookSize')"
      v-model="effort['hookSize']"
      :required="hasEffortByGearHookSizeId === $const.MANDATORY"
      :options="this.systemsLists.hookSize"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />

    <Select
      v-if="hasEffortByGearHookTypeId"
      :label="$t('editTrip.effortStep.hookType')"
      v-model="effort['hookType']"
      :required="hasEffortByGearHookTypeId === $const.MANDATORY"
      :options="this.systemsLists.hookType"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="hasEffortByGearHight"
      :label="$t('editTrip.gearInfo.height')"
      :id="'height ' + index"
      v-model="effort['height']"
      :min="1"
      :max="9999"
      unit="cm"
      :required="hasEffortByGearHight === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <UnitConverter
      v-if="hasEffortByGearHight"
      :label="$t('editTrip.gearInfo.diameter')"
      :id="'diameter ' + index"
      v-model="effort['diameter']"
      :min="1"
      :max="9999"
      unit="cm"
      :required="hasEffortByGearHight === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <UnitConverter
      v-if="hasEffortGearSpacing"
      :label="$t('editTrip.gearInfo.spacing')"
      :id="'spacing ' + index"
      v-model="effort['spacing']"
      :min="1"
      :max="9999"
      unit="cm"
      :required="hasEffortGearSpacing === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <GenericInput
      v-if="hasEffortByGearOtherGearDsc"
      :label="$t('editTrip.gearInfo.otherGear')"
      v-model="effort['otherGear']"
      type="text"
      :required="hasEffortByGearOtherGearDsc === $const.MANDATORY"
      :maxlength="50"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="hashookGape"
      :label="$t('editTrip.gearInfo.hookGape')"
      v-model="effort['hookGape']"
      :required="hashookGape === $const.MANDATORY"
      :min="0"
      :max="999.9"
      unit="mm"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <IntegerInput
      v-if="hasNbHooksPerSkate"
      :id="'hooksPerSkate ' + index"
      :label="$t('editTrip.gearInfo.hooksPerSkate')"
      v-model="effort['hooksPerSkate']"
      :min="1"
      :max="999999"
      :required="hasNbHooksPerSkate === $const.MANDATORY"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <GenericInput
      v-if="hasEffortByGearModel"
      :label="$t('editTrip.gearInfo.model')"
      v-model="effort['model']"
      type="text"
      :required="hasEffortByGearModel === $const.MANDATORY"
      :maxlength="50"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="hasEffortGearSize"
      :label="$t('editTrip.gearInfo.size')"
      :id="'size ' + index"
      v-model="effort['size']"
      :min="1"
      :max="maxGearSize"
      unit="cm"
      :required="hasEffortGearSize === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <GenericInput
      v-if="hasEscapeConfig"
      :label="$t('editTrip.gearInfo.escapeConfig')"
      v-model="effort['escapeConfig']"
      type="text"
      :required="hasEscapeConfig === $const.MANDATORY"
      :maxlength="50"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="hasEscapes"
      :id="'escapeDiameter ' + index"
      :label="$t('editTrip.gearInfo.escapeDiameter')"
      v-model="effort['escapeDiameter']"
      :min="1"
      :max="9999"
      unit="mm"
      :required="hasEscapes === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <IntegerInput
      v-if="hasEscapes"
      :id="'escapeCount ' + index"
      :label="$t('editTrip.effortStep.escapeCount')"
      v-model="effort['escapeCount']"
      :min="0"
      :max="99"
      :required="hasEscapes === $const.MANDATORY"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <Select
      v-if="hasSelectivityDevice"
      :label="$t('editTrip.effortStep.selectivityDevice')"
      v-model="effort['selectivityDevice']"
      :required="hasSelectivityDevice === $const.MANDATORY"
      :options="systemsLists.gearSelectivityDevices"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="hasRopeLength"
      :id="'headropeLength' + index"
      :label="$t('editTrip.effortStep.headropeLength')"
      v-model="effort['headropeLength']"
      :min="1"
      :max="999"
      unit="m"
      :required="hasRopeLength === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <UnitConverter
      v-if="hasRopeLength"
      :id="'footropeLength ' + index"
      :label="$t('editTrip.effortStep.footropeLength')"
      v-model="effort['footropeLength']"
      :min="1"
      :max="999"
      unit="m"
      :required="hasRopeLength === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <UnitConverter
      v-if="hasRopeLength"
      :id="'riseNet' + index"
      :label="$t('editTrip.effortStep.riseNet')"
      v-model="effort['riseNet']"
      :min="0"
      :max="99.9"
      unit="m"
      :required="hasRopeLength === $const.MANDATORY"
      @error="addErrorToParent"
      :refresh="showErrorData"
    />
    <GenericInput
      v-if="hasGearName"
      :label="$t('editTrip.effortStep.gearNameNum')"
      v-model="effort['gearNameNum']"
      type="text"
      :maxlength="50"
      :required="hasGearName === $const.MANDATORY"
      @error="addErrorToParent"
      :refeshError="showErrorData"
    />
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import DecimalInput from "@/components/subformEditTrip/widgets/DecimalInput.vue";

import { mapGetters, mapState } from "vuex";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  components: {
    GenericInput,
    DecimalInput,
    Select,
    IntegerInput,
    Popup,
    UnitConverter
  },
  props: {
    addErrorToParent: Function,
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    effort: Object,
    index: Number
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      subform: state => state.editTripSubform.subform
    }),
    maxGearSize() {
      return this.getPropertyValue("maxGearSize", {}, 999);
    },
    hasMeshType() {
      return this.getPropertyValue("hasMeshType", {
        subforms: this.subform
      });
    },
    hasMonofilamentGauge() {
      return this.getPropertyValue("hasMonofilamentGauge", {
        subforms: this.subform
      });
    },
    hasGangionLength() {
      return this.getPropertyValue("hasGangionLength", {
        subforms: this.subform
      });
    },
    hasEffortByGearHight() {
      return this.getPropertyValue("hasEffortByGearHight", {
        subforms: this.subform
      });
    },
    hasEffortByGearModel() {
      return this.getPropertyValue("hasEffortByGearModel", {
        subforms: this.subform
      });
    },
    hasGangeSpacing() {
      return this.getPropertyValue("hasGangeSpacing", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEscapes() {
      return this.getPropertyValue("hasEscapes", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortByGearOtherGearDsc() {
      return this.getPropertyValue("hasEffortByGearOtherGearDsc", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasSelectivityDevice() {
      return this.getPropertyValue("hasSelectivityDevice", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasGearName() {
      return this.getPropertyValue("hasGearName", {
        subforms: this.subform
      });
    },
    hasRopeLength() {
      return this.getPropertyValue("hasRopeLength", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    gearTypes() {
      return this.getPropertyValue("gears", { subforms: this.subform }, []);
    },
    gearTypeOptions() {
      return this.buildOptions(this.gearTypes, this.systemsLists.gearType);
    },
    gearSubtypes() {
      return this.getPropertyValue(
        "gearSubtypes",
        { gears: this.effort.gears },
        []
      );
    },
    gearSubtypeOptions() {
      return this.buildOptions(
        this.gearSubtypes,
        this.systemsLists.gearSubTypes,
        this.hasEffortGearSubtype === this.$const.MANDATORY
      );
    },
    hasEffortGearLength() {
      return this.getPropertyValue("hasEffortGearLength", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortGearSubtype() {
      return this.getPropertyValue("hasEffortGearSubtype", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortGearSpacing() {
      return this.getPropertyValue("hasEffortGearSpacing", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortByGearHookTypeId() {
      return this.getPropertyValue("hasEffortByGearHookTypeId", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortByGearHookSizeId() {
      return this.getPropertyValue("hasEffortByGearHookSizeId", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hashookGape() {
      return this.getPropertyValue("hashookGape", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasNbHooksPerSkate() {
      return this.getPropertyValue("hasNbHooksPerSkate", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortMeshSize() {
      return this.getPropertyValue("hasEffortMeshSize", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortGearSize() {
      return this.getPropertyValue("hasEffortGearSize", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasTrapSize() {
      return this.getPropertyValue("hasTrapSizeId", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    trapSizes() {
      return this.getPropertyValue("trapSizes", { subforms: this.subform }, []);
    },
    trapSizeOptions() {
      return this.buildOptions(
        this.trapSizes,
        this.systemsLists.trapSizes,
        this.hasTrapSize === this.$const.MANDATORY
      );
    },
    hasEscapeConfig() {
      return this.getPropertyValue("hasEscapeConfig", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    }
  },
  watch: {
    hasMeshType: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.effort.meshType;
        }
      }
    },
    hasEffortGearSubtype: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.effort.subType;
        }
      }
    },
    hasEffortMeshSize: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.effort.meshSize;
        }
      }
    },
    hasTrapSize: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.effort.trapSize;
        }
      }
    },
    gearTypeOptions: {
      immediate: true,
      handler: function(newOptions) {
        // TODO: This should be taken care of by the Select component.
        // If there is a single option in the list, preselect it.
        if (newOptions.length === 2) {
          this.effort.gears = newOptions[1].value;
        }
      }
    }
  }
};
</script>
