// TODO: Rename 'subscriptionName' to 'propertyName' to better reflect its meaning !!
export const mapping = {
  GearsTypes: {
    stateName: "gearTypeOptions",
    subscriptionName: "gears",
    list: "gearType"
  },
  Subforms: {
    stateName: "subformOptions",
    subscriptionName: "subforms",
    list: "subforms"
  },
  TargetSpecies: {
    stateName: "targetSpecies",
    subscriptionName: "targetSpecies",
    list: "species"
  },
  maxTagCatchLatitude: {
    stateName: "maxTagCatchLatitude",
    subscriptionName: "maxTagCatchLatitude",
    list: null
  },
  minTagCatchLatitude: {
    stateName: "minTagCatchLatitude",
    subscriptionName: "minTagCatchLatitude",
    list: null
  },
  maxTagCatchLongitude: {
    stateName: "maxTagCatchLongitude",
    subscriptionName: "maxTagCatchLongitude",
    list: null
  },
  minTagCatchLongitude: {
    stateName: "minTagCatchLongitude",
    subscriptionName: "minTagCatchLongitude",
    list: null
  },
  HailOutCallProviders: {
    stateName: "hailOutCallProviders",
    subscriptionName: "hailOutCallProviders",
    list: "serviceProvider"
  },
  HailInCallProviders: {
    stateName: "hailInCallProviders",
    subscriptionName: "hailInCallProviders",
    list: "serviceProvider"
  },
  GridMapMagdalenIslands: {
    stateName: "gridMapMagdalenIslands",
    subscriptionName: "gridMapMagdalenIslands",
    list: null
  },
  maxCrewNb: {
    stateName: "maxCrewNb",
    subscriptionName: "maxCrewNb",
    list: null
  },
  useSimplifiedEffort: {
    stateName: "useSimplifiedEffort",
    subscriptionName: "useSimplifiedEffort",
    list: null
  },
  hasTransfer: {
    stateName: "hasTransfer",
    subscriptionName: "hasTransfer",
    list: null
  }
};
