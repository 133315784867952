<template>
  <div name="TransportViewer" class="towEntriesContainer">
    <h4 class="level1 viewStep">
      {{ $t("editTrip.lastStep.transport") }}
    </h4>
    <fieldset class="fieldsetViewTrip">
      <b-row>
        <b-col v-if="transport.remark" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.remarkTitle')"
            :value="transport.remark"
            type="text"
          />
        </b-col>
        <b-col v-if="transport.vessel.name" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.vesselName')"
            :value="transport.vessel.name"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.vesselNumber')"
            :value="transport.vessel.registeredNumber"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.pondNumber')"
            :value="transport.pondNumber"
            type="text"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.isLoad')"
            :value="transport.isLoad"
            :options="$t('productDestination.items')"
            type="select"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('carrier.datetime')"
            :value="$showDate(transport.datetime)"
            type="text"
          />
        </b-col>
      </b-row>
      <h4>{{ $t("editTrip.lastStep.details") }}</h4>
      <b-row>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.specie')"
            :value="transport.cargos[0].species"
            :options="systemsLists.species"
            type="select"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.lastStep.productForm')"
            :value="transport.cargos[0].productForm"
            :options="systemsLists.productForm"
            type="select"
          />
        </b-col>
        <b-col lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.lastStep.weight')"
            :value="transport.cargos[0].weight"
            unit="weight"
            viewOnly
          />
        </b-col>
        <b-col v-if="transport.cargos[0].remark" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.remarkTitle')"
            :value="transport.cargos[0].remark"
            type="text"
          />
        </b-col>
      </b-row>
    </fieldset>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "TransportViewer",
  components: {
    LabelView,
    UnitConverter
  },
  props: {
    transport: Object
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      userProfile: state => state.userProfile
    })
  },
  methods: {}
};
</script>
